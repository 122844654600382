import axios from 'axios';
import {store} from '@/store';

export async function partialFetchData(method = 'post', url = '/', data = {}, headers = {}, callback, successCallback = (() => {}), activate_loader=false) {
  if (method.toLowerCase() !== 'get') {
    if (method.toLowerCase() === 'delete') {
      data = { data: data };
    }
    data.token = store.state.user.token;
  } else {
    headers.Token = store.state.user.token;
  }

  let loader;
  if(activate_loader)
    loader = setTimeout(function() {
      store.state.isLoading = true;
    }, 1000);

  return axios({
    url:            url,
    data:           data,
    headers:        headers,
    method:         method,
    timeout:        0,
    onDownloadProgress: (evt)=>{ callback(evt); }
  
  }).finally(()=>{
    if(loader) {
      clearTimeout(loader);
      store.state.isLoading = false;
    }
    successCallback();
  })
}
