<template>
  <div style="display: flex; align-items: center; height: calc(100% + 2px)">
    <div
      v-if="isHeader === false"
      class="blue-border"
    />
    <v-icon
      v-if="isHeader === true"
      @click="showHideEvents(groupKey)"
    >
      {{ isOpened ? 'irz-chevron-up' : 'irz-chevron-down' }}
    </v-icon>
    <span
      v-if="isHeader === true"
      style="margin-right: 10px;"
    >
      {{ '(' + count + ')' }}
    </span>
  </div>
</template>

<script>

export default {
    props: {
        isOpened: {
          type: Boolean,
          required: false,
          default: false,
        },
        groupKey: {
          type: String,
          required: true,
          default: null,
        },
        isHeader: {
          type: Boolean,
          required: false,
          default: null,
        },
        showHideEvents: {
            type: Function,
            required: true
        },
        count: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {

        }
    },
}
</script>


<style>
.notHeader {
  margin-left: 44px;
}

.blue-border {
  height: 100%;
  width: 3px; 
  background-color: #0052ca; 
  margin-right: 3px;
}

.underfineHeader {
  margin-left: 48px;
}
</style>