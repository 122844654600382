<template>
  <v-container :fluid="true">
    <v-row
      justify="center"
      wrap
    >
      <v-col>
        <material-card
          :title="title"
          :text="text"
          :backable="backable"
        >
          <v-form>
            <v-container>
              <v-row
                wrap
                column
                align-center
                fill-height
              >
                <v-col cols="12">
                  <v-select
                    v-if="fileTypeItems"
                    v-model="file_type"
                    :label="$t('Custom.FileUpload.filetype')"
                    :items="fileTypeItems"
                  />
                </v-col>
                <v-col cols="12">
                  <custom-file-select
                    v-model="file"
                    style="width: 100%"
                    @update:modelValue="uploadFile"
                  />
                </v-col>
                <v-col
                  cols="12"
                  style="display: flex;"
                >
                  <v-progress-circular
                    :rotate="90"
                    :size="100"
                    :width="15"
                    :model-value="uploadPercentage"
                    style="margin: auto;"
                  >
                    {{ uploadPercentage }}%
                  </v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";

export default {
    name: name,
    props: {
        title: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        url: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        fallback: {
            type: String,
            default: ""
        },
        backable: {
            type: Boolean,
            default: false
        },
        fileTypeItems: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            file: null,
            file_type: this.fileTypeItems[0],
            uploadPercentage: 0
        };
    },
    computed: {
        ...mapState(["user"]),
        getURL() {
            return this.url + (this.file_type ? "/" + this.file_type : "")
        }
    },
    methods: {
        uploadFile(file) {
            if (!file || !file.size) return;
            this.progressBarVisibility = true;
            let formData = new FormData();
            formData.append("file", file);
            axios
                .post(this.getURL, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Token: this.user.token
                    },
                    onUploadProgress: (progressEvent) => {
                        this.uploadPercentage = parseInt(
                            Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        );
                    }
                })
                .then(() => {
                    this.$store.dispatch('addInfoMessage', this.$t('Custom.Push.file_upload_success'));
                    setTimeout(() => {
                        this.$router.push(this.fallback);
                    }, 2000);
                })
                .catch(err => {
                    this.$store.dispatch('addInfoMessage', this.$t('Custom.Push.file_upload_error') + this.getURL + ': ' + this.$t('Custom.Error.' + err.response.data));
                });
        }
    },
};
</script>
