<template>
  <helper-table
    v-model="newSelected"
    :variant="variant"
    :items="filteredDeviceList"
    :headers="headers"
    :show-select="showSelect"
    item-value="ident"
    :sort-by="sortBy"
    :prefix-cookies-name="prefixCookiesName"
    @update:model-value="updateSelect"
  >
    <template
      v-for="(_, name) in $slots"
      :key="name"
      #[`${name}`]="{ item }"
    >
      <slot
        :name="name"
        :item="item"
      />
    </template>
  </helper-table>
</template>

<script>
    export default{
        props: {
            // prop for custom row height
            variant: {
                type: String,
                default: 'comfortable',
            },
            deviceSupport: {
                type: Function,
                required: false,
                default: () => { return false }
            },
            search: {
                type: Object,
                required: true
            },
            showSelect: {
                type: Boolean,
            },
            prefixCookiesName: {
                type: String,
                required: false,
                default: "",
            },
            modelValue: {
                type: Array,
                required: false,
                default() {
                    return [];
                }
            },
            headers: {
                type: Array,
                required: true
            },
            sortBy: {
                type: Array,
                required: false,
                default() {
                    return []
                }
            },
        },
        emits: ['update:modelValue'],
        data() {
            return {
                newSelected: this.modelValue,
            }
        },
        computed: {
            devices() {
                return this.$store.state.devices;
            },

            filteredDeviceList() {
                let devs = _.orderBy(Array.from(this.devices.values()));
                
                if (this.search.filterTags?.size > 0) {
                    let selectedTags = [].concat(...this.search.filterTags).map(elem => {
                        return elem.toString().toLowerCase();
                    });
                    
                    const isNot = this.search.filterIsNot;
                    const filterDevs = devs.filter(dev => {
                        let deviceTags = [].concat(dev.tags ? dev.tags : []).map(elem => {
                            return elem.toString().toLowerCase();
                        });
                        
                        const diff = _.difference(selectedTags, deviceTags);
                        const and = this.search.filterBehaviour === 'and';
                        
                        return !and && diff.length < selectedTags.length || diff.map(dif => {
                            return dev.ident?.toLowerCase().includes(dif) ||
                            dev.description?.toLowerCase().includes(dif) ||
                            dif.startsWith("@") && this.deviceSupport(dev, dif.substring(1).split('=', 2))
                        }).reduce((a, b) => and ? a && b : a || b, and);
                    });

                    devs = isNot ? _.difference(devs, filterDevs) : filterDevs;
                }
                
                return devs;
            },
        },
        methods: {
            updateSelect() {
                this.$emit("update:modelValue", this.newSelected);
            },
        }
    }
</script>