let declension = function (n, vs, space) {
    space = space || ' ';

    let nn = Math.abs(n) % 100;
    if (nn > 10 && nn < 20) return n + space + vs[2];

    nn = nn % 10;
    if (nn > 1 && nn < 5) return n + space + vs[1];
    if (nn === 1) return n + space + vs[0];
    return n + space + vs[2];
};

let getFTime = function (tmst, needDate) {
    if (tmst === undefined || tmst == null || tmst === 0) {
        return '';
    }

    let dt = new Date(tmst);

    let prefix = '';
    if (needDate) {
        let Y = ('0000' + dt.getFullYear()).substr(-4);
        let D = ('0' + dt.getDate()).substr(-2);
        let M = ('0' + (dt.getMonth() + 1)).substr(-2);
        prefix = [Y, M, D].join('-') + ' ';
    }

    let h = ('0' + dt.getHours()).substr(-2);
    let m = ('0' + dt.getMinutes()).substr(-2);
    let s = ('0' + dt.getSeconds()).substr(-2);
    return prefix + [h, m, s].join(':');
};

const time = {
    install(vue) {
        vue.config.globalProperties.$DAY = 24 * 60 * 60;

        vue.config.globalProperties.$getDate = function (tmst) {
            return getFTime(tmst, true);
        };

        vue.config.globalProperties.$getTime = function (tmst) {
            return getFTime(tmst, false);
        };

        vue.config.globalProperties.$tomorrow = function (date) {
            return date.getTime() + 86400000;
        };

        vue.config.globalProperties.$midnight = function (date) {
            let d = new Date(date);
            d.setHours(0);
            d.setMinutes(0);
            d.setSeconds(0);
            d.setMilliseconds(0);
            return d;
        };

        vue.config.globalProperties.$today = function () {
            let d = new Date(vue.config.globalProperties.$midnight(new Date()));
            return d;
        };

        vue.config.globalProperties.$yesterday = function (date) {
            let d = new Date(date);
            d.setDate(date.getDate() - 1);
            return d;
        };

        vue.config.globalProperties.$getLastWeek = function (date) {
            let d = new Date(date);
            d.setDate(date.getDate() - 7);
            return d;
        };

        vue.config.globalProperties.$isBetween = function (checkingDate, startDate, stopDate) {
            return vue.config.globalProperties.$getDayDiff(checkingDate, startDate.getTime())
                >= 0 && 0 > vue.config.globalProperties.$getDayDiff(
                    checkingDate, stopDate.getTime())
        };

        vue.config.globalProperties.$dateDiff = function (date1, date2) {
            return Math.floor((date1.getTime() - date2.getTime()) / 1000);
        };

        vue.config.globalProperties.$getDayDiff = function (now, day_diff) {
            return Math.floor((now - day_diff) / 1000 / 3600 / 24)
        };

        vue.config.globalProperties.$timeSince = function (tmst, from) {
            if (!tmst)
                return;

            if (!from)
                from = new Date().getTime()

            let d = (from - tmst) / 1000;
            d = Math.floor(d);
            if (d < 60)
                return true;

            d = Math.floor(Math.abs(d));

            if (!d) return '';

            let chunkDeclension = (n, t) => declension(n, vue.config.globalProperties.$tm('Plugins.Time.' + t), ' ');

            let chunks = [
                [60 * 60 * 24 * 365,    n => chunkDeclension(n, 'year')],
                [60 * 60 * 24 * 30,     n => chunkDeclension(n, 'month')],
                [60 * 60 * 24 * 7,      n => chunkDeclension(n, 'week')],
                [60 * 60 * 24,          n => chunkDeclension(n, 'day')],
                [60 * 60,               n => chunkDeclension(n, 'hour')],
                [60,                    n => chunkDeclension(n, 'minute')],
                [1,                     n => chunkDeclension(n, 'second')]
            ];

            let i = 0;
            let c = 0;
            for (; i < chunks.length; i++) {
                c = Math.floor(d / chunks[i][0]);
                if (c !== 0) break;
            }
            let s = chunks[i][1](c);

            if (i + 1 < chunks.length - 1) {
                let x = chunks[i + 1];
                let c2 = Math.floor((d - chunks[i][0] * c) / x[0]);
                if (c2)
                    s += ', ' + x[1](c2);
            }

            return s;
        };

        vue.config.globalProperties.$timeAgo = function (tmst) {
            return this.$timeSince(tmst);
        };

        vue.config.globalProperties.$parseTime = function (x) {
            let arr = x.split(" ");
            if (arr.length === 1) {
                const t = x[x.length - 1];
                var a = parseInt(x);
                switch (t) {
                    case 'd': a *= 24;
                    case 'h': a *= 60;
                    case 'm': a *= 60;
                }
                return a;
            }
            return arr.map(x => this.$parseTime(x)).reduce((a, b) => a + b);
        };

        vue.config.globalProperties.$toUTC = function (x) {
            let delta = new Date().getTimezoneOffset() * 60;
            let utc = x + delta;
            delta = 0;
            if (utc < 0) delta++;
            else if (utc >= vue.config.globalProperties.$DAY) delta--;
            utc += delta * vue.config.globalProperties.$DAY;
            return utc;
        }
    }
};

export { time };
