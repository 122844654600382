import {store} from "../store";
import {i18n} from "../i18n";
import { fetchData } from './fetchData';

const files = {
    install (){},

    getURL: function (file) {
        let file_type = file.name.split('.').pop();
        file_type = ({'bin': 'firmware', 'ipk': 'package'})[file_type] || 'file';
        return '/api/file' + (file_type ? "/" + file_type : "")
    },

    uploadFile: async function (file, callback) {
        if (!file || !file.size || !file.name) {
            store.state.isLoading = false;
            return;
        }
        if (file.size / 1024 / 1024 >= 25) {
            store.state.isLoading = false;
            store.dispatch('addWarningMessage',
                i18n.global.t('Custom.Push.too_large_file') + ' (' + file.name + ')');
            return;
        }
        const filename = file.name;
        let formData = new FormData();
        formData.append("file", file);
        let url = this.getURL(file);

        return fetchData('post', url, formData, {
            "Content-Type": "multipart/form-data",
            Token: store.state.user.token
        }, () => {return false})
        .then(() => {
            setTimeout(() => {
                store.dispatch('addWarningMessage',
                    i18n.global.t('Custom.Push.file_upload_success') + ' (' + filename + ')');
            }, 1500);
        })
        .catch(err => {
            store.dispatch('addWarningMessage', i18n.global.t('Custom.Push.file_upload_error') + ': ' +
            i18n.global.t('Custom.Error.' + err.response.data) + ' (' + filename + ')');
        })
        .finally(() => {
            callback();
            store.state.isLoading = false;
        });
    },

    deleteSelected: async function (selected) {
        fetchData('delete', '/api/file/many', {'targets': selected})
            .then(() => {
                store.dispatch('addInfoMessage', i18n.global.t('Custom.Push.success'));
            });
    },

    deleteFile: async function (file_id) {
        fetchData('delete', '/api/file/' + file_id)
            .then(() => {
                store.dispatch('addInfoMessage', i18n.global.t('Custom.Push.success'));
            })
    },

    downloadArch: async function (filename) {
        store.state.isLoading = false;
        fetchData('EXPORT', '/api/' + filename, {}, {'Token': store.state.user.token, responseType: 'blob'}, () => {return false})
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename + '.csv');
            document.body.appendChild(link);
            link.click();
        }).catch((err) => {
            switch (err.response.status) {
                case (401, 403):
                    store.dispatch('logout');
                    document.location = '/login';
                    return

                case 500:
                    store.dispatch('addInfoMessage', i18n.global.t('Custom.Push.server_error'));
                    break;

                case 400:
                    store.dispatch('addInfoMessage', i18n.global.t('Custom.Error.PERMISSIONS_DENIED'));
                    break;

                default:
                    store.dispatch('addInfoMessage', i18n.global.t('Custom.Push.server_error') + ' by ' + err.response.url + ': ' + i18n.global.t('Custom.Error.FILE_NOT_FOUND'));
                    break;
            }
        });
    },

    downloadFile: async function (file_id, filename) {
        store.state.isLoading = false;
        fetchData('get', '/api/file/' + file_id, {}, {'Token': store.state.user.token, responseType: 'blob'}, () => {return false})
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }).catch((err) => {
            switch (err.response.status) {
                case (401, 403):
                    store.dispatch('logout');
                    document.location = '/login';
                    return

                case 500:
                    store.dispatch('addInfoMessage', i18n.global.t('Custom.Push.server_error'));
                    break;

                case 400:
                    store.dispatch('addInfoMessage', i18n.global.t('Custom.Error.PERMISSIONS_DENIED'));
                    break;

                default:
                    store.dispatch('addInfoMessage', i18n.global.t('Custom.Push.server_error') + ' by ' + err.response.url + ': ' + i18n.global.t('Custom.Error.FILE_NOT_FOUND'));
                    break;
            }
        });
    },
}

export {files};
