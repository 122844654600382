<template>
  <div v-show="info_msg && info_msg[0]">
    <v-snackbar
      v-model="snackbar"
      theme="light"
      style="z-index: 1010;"
      :timeout="timeout"
    >
      <div class="v-snackbar-content">
        <v-icon
          style="margin: auto 0 auto 0;"
          color="white"
          class="mr-3"
        >
          mdi-bell
        </v-icon>
        <div @click="info_msg && info_msg[0].url ? $router.push(info_msg[0].url) : (snackbar = false)">
          {{ info_msg[0] && info_msg[0].message ? info_msg[0].message : info_msg[0] }}
        </div>
        <v-icon
          style="margin: auto 0 auto auto;"
          size="21"
          @click="snackbar = false"
        >
          irz-close-circle
        </v-icon>
      </div>
    </v-snackbar>
  </div>
</template>

<script>

import {mapState} from 'vuex';

export default {
    data() {
        return {
            snackbar: false,
            timeout: 5000,
        };
    },

    computed: {
        ...mapState(['info_msg']),
    },

    watch: {
        info_msg: {
            handler(n) {
                if (n.length)
                    setTimeout(() => {
                        this.timeout = 5000 + (n.length && n[0] && n[0].priority > 0 ? Math.random() : 0)
                        this.snackbar = true
                    }, 50)
                else this.snackbar = false
            },
            deep: true
        },

        snackbar(n) {
            if (n === false) {
                this.info_msg.shift();
            }
        },

    },
};
</script>

<style scoped>

.v-snackbar-content {
    display: flex;
}

.v-snackbar .v-icon {
    margin-top: auto;
}

</style>
