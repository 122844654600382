<template>
  <v-footer
    id="core-footer"
  >
    <!-- <div class="footer-items">
          <span
            v-for="link in links"
            :key="link.name"
          >
            <a
              :href="link.Link"
              class="tertiary--text footer-links">{{ link.name }}</a>
          </span>
        </div> -->
    <v-spacer />
    <span class="font-weight-light caption copyright">
      &copy;
      {{ (new Date()).getFullYear() }}
      <a
        href="https://irz.net/"
        target="_blank"
      >{{ $t('Core.Footer.irz') }}</a>
    </span>
  </v-footer>
</template>

<script>
export default {
    // data: () => ({
    //   links: [
    //     { name: 'Help', Link: '/help' },
    //     { name: 'Send Report', Link: 'https://www.radiofid.ru/help/#dialog-order' },
    //     { name: 'Privacy Policy', Link: 'https://www.radiofid.ru/company/privacy-policy/' },
    //     { name: 'Contacts', Link: 'https://www.radiofid.ru/contacts/https://www.radiofid.ru/' }
    //   ]
    // })
}
</script>

<style>
#core-footer {
    z-index: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: small;
    background: transparent;
}
</style>
