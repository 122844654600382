<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    theme="dark"
    :floating="true"
    persistent
    :mobile-break-point="breakpoint"
    :width="isLoggedIn ? 290 : Math.max(480, Math.floor(0.3 * width))"
    :style="{'z-index': '1010', 'background': (isLoggedIn ? '#151515' : '#023AB0')}"
  >
    <v-list
      v-if="isLoggedIn && !factoryPassword"
      class="fill-height"
    >
      <v-list-item>
        <v-list-item-title 
          class="card-tabs v-list__tile__title ml-1"
        >
          <v-btn 
            icon="irz-logo"
            to="/home"
            variant="text"
          />
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <!-- Links for Root -->
      <v-col v-if="$store.state.user.role === 1">
        <v-list-item
          v-for="(link, i) in linksRoot"
          :key="i"
          :to="link.to"
          class="v-list__tile"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title v-text="link.text" />
        </v-list-item>
      </v-col>

      <!-- //Links for Root -->

      <!-- Links for Others -->
      <v-col v-if="$store.state.user.role !== 1">
        <v-list-item
          v-for="(link, i) in links"
          :key="i"
          :to="link.to"
          class="v-list__tile"
          :style="{'display': checkRole(link.to)}"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title v-text="link.text" />
        </v-list-item>
      </v-col>

      <!-- //Links for Others -->
    </v-list>

    <div
      v-if="!isLoggedIn"
      class="mt-16 ml-16 mr-2 pl-16"
    >
      <h2 class="v-list__tile__title ml-1">
        <v-btn
          icon="irz-logo"
          to="/home"
          variant="text"
        />
      </h2>

      <div style="margin-top: 40%">
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="font-weight-bold"
          style="font-size: 48px !important; line-height: 48px" 
          v-html="$t('Core.Drawer.welcome')"
        />
        <!--eslint-enable-->

        <!-- eslint-disable vue/no-v-html -->
        <div
          class="font-weight-regular mt-3"
          style="font-size: 18px !important; line-height: 18px" 
          v-html="$t('Core.Drawer.system')"
        />
        <!--eslint-enable-->
      </div>
    </div>

    <div v-if="(tag || version) && $route.name === 'profile'">
      <span
        v-if="!tag || tag.startsWith('v')"
        class="font-weight-light caption tag"
      >
        {{ tag || version }}
      </span>

      <span
        v-else
        class="font-weight-light caption tag"
      >
        <v-icon
          class="mr-1"
          style="color: cyan"
        >
          mdi-gitlab
        </v-icon>
        <a
          :href="`http://gitlab.radiofid.ru/Routers/Developing/link/front/-/commit/${tag}`"
          target="_blank"
        >{{ version }}</a>
      </span>
    </div>
  </v-navigation-drawer>
</template>

<script>

import {mapGetters, mapMutations, mapState} from "vuex";

import ReconnectingWebSocket from 'reconnecting-websocket';

export default {
    data: () => ({
        breakpoint: window.BREAK_POINT,
        responsive: false,
        addEvents: [],
        tag: process.env.VUE_APP_GIT_COMMIT,
        version: process.env.VUE_APP_VERSION,
        width: 900,
    }),

    computed: {
        ...mapState(["image", "color", "devices"]),
        ...mapGetters(["hasAccounting", "isLoggedIn", "factoryPassword"]),
        inputValue: {
            get() {
                return this.$store.state.drawer;
            },
            set(val) {
                this.setDrawer(val);
            }
        },
        linksRoot() {
            return [
                {
                    to: "/accounting",
                    icon: "irz-cog-outline",
                    text: this.$t('Core.Drawer.accounting')
                },
            ]
        },
        links() { return [
            {
                to: "/dashboard",
                icon: "irz-view-dashboard",
                text: this.$t('Core.Drawer.dashboard')
            },
            {
                to: "/events",
                icon: "irz-calendar-alert",
                text: this.$t('Core.Drawer.events')
            },
            {
                to: "/commands",
                icon: "irz-keyboard",
                text: this.$t('Core.Drawer.commands')
            },
            {
                to: "/scripts",
                icon: "irz-code-braces",
                text: this.$t('Core.Drawer.scripts')
            },
            {
                to: "/accounting",
                icon: "irz-cog-outline",
                text: this.$t('Core.Drawer.accounting')
            },
        ].filter(x => x.condition !== false) },

    },

    mounted() {
        if (this.$store.getters.isAdmin && !this.$store.getters.factoryPassword) {
            this.$store.dispatch("getUsers");
            this.$store.dispatch('getEvents');
        }

        let ws_uri = location.hostname + ":" + location.port + "/api/ws/"

        this.$store.state.ws = new ReconnectingWebSocket((location.protocol === "https:" ? "wss" : "ws") + "://" + ws_uri, null, {
            automaticOpen: true,
            debug: false,
            timeoutInterval: 15000,
            reconnectDecay: 0,
        })

        this.$fetchSocket(this.$store.state.ws);

        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
        this.width = window.innerWidth;

        if (this.$store.state.ws) {
            if (this.$store.state.ws.appMessage)
                this.$store.state.ws.appMessage = null;

            this.$store.state.ws.appMessage = (data) => {
                if (data.type === "alerts") {
                    let evts = 0;
                    data.content.forEach(item => {
                        if (item.variant === 'command' && data.method === "update") {
                            if (item.collection !== 'crontab.tasks') return;

                            let old = false;
                            let dev = this.devices.get(item.device);
                            dev.tasks.forEach((t, i) => {
                                if (t._id === item._id) {
                                    dev.tasks[i] = item;
                                    old = true;
                                }
                            });

                            if (!old) dev.tasks.push(item);
                        } else if (item.variant === 'event') {
                            evts++;
                            let dev = this.devices.get(item.device);
                            if (dev) {
                                let cnt = dev.events || 0;
                                dev.events = cnt + 1;
                            }
                        }
                    });

                    if (evts > 0) {
                        this.$store.dispatch(
                            'addInfoMessage',
                            {
                                message: this.$t('Custom.Push.need_your_action'),
                                url: '/events'
                            }
                        );
                    }
                } else if (data.type === "devices") {
                    if (data.method === "update") {
                        data.content.forEach(item => {
                            let dev = this.devices.get(item.device);
                            delete item.device;

                            if (dev)
                                Object.entries(item).filter(x => ['time', 'meta'].includes(x[0])).forEach(e => {
                                    dev[e[0]] = e[1];
                                });
                        });
                    } else if (data.method === "delete") {
                        data.content.forEach(item => {
                            this.$store.dispatch("deleteDevice", item);
                        });
                    } else if (data.method === "put") {
                        data.content.forEach(item => {
                            if (!this.devices.get(item.device))
                                this.$store.dispatch("addNewDevice", item);
                        });
                    }
                } else if (data.type === "collects") {
                    if (data.method === "update") {
                        data.content.forEach(item => {
                            let dev = this.devices.get(item.ident);
                            delete item.ident;

                            if (dev)
                                Object.entries(item).forEach(e => {
                                    dev[e[0]] = e[1].data;
                                })
                        });
                    }
                } else if (data.type === "peers") {
                    if (data.method === "update") {
                        data.content.map(item => {
                            this.peers.map((peer, num) => {
                                if (peer['dev-id'] === item['dev-id']) {
                                    this.peers[num] = Object.assign(peer, item);
                                    this.peers[num]['conns'] = item.conns;
                                }
                            })
                        })
                    } else if (data.method === "insert") {
                        data.content.map(item => {
                            this.peers.push(item)
                        })
                    } else if (data.method === "delete") {
                        data.content.map(item => {
                            this.peers.map((peer, num) => {
                                if (peer['dev-id'] === item['dev-id']) {
                                    this.peers.splice(num, 1)
                                }
                            })
                        })
                    }
                } else if (data.type === "approval") {
                    this.$store.dispatch(
                        'addInfoMessage',
                        {
                            message: this.$t('Custom.Push.need_your_action'),
                            url: '/events'
                        }
                    );
                }
            };
        }
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.onResponsiveInverted);
    },

    methods: {
        ...mapMutations(["setDrawer", "setColor", "toggleDrawer"]),
        onResponsiveInverted() {
            this.responsive = window.innerWidth < window.BREAK_POINT;
            this.width = window.innerWidth;
        },
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push('/login')
                })
        },
        checkRole(pLink) {
            if (pLink === "/scripts") {
                if (this.$store.state.user.role === 4) {
                    return "none";
                }
            }

            if (pLink === "/templates") {
                if (this.$store.state.user.role !== 2) {
                    return "none";
                }
            }
        },
    }
};
</script>

<style lang="scss">
#app-drawer {
    .v-list__tile__title .v-btn,
    .v-list__tile__title .v-btn .mdi {
        width: fit-content;
    }

    .v-list__tile__title .v-btn {
        border-radius: 5px;
        padding: 12px;
    }

    .mdi:before {
        filter: invert(1) brightness(10000);
    }

    .v-list-item {
        border-radius: 4px;

        &--title {
            letter-spacing: 3px;
            font-variant: small-caps;
        }

        &--buy {
            margin-top: auto;
            margin-bottom: 17px;
        }
    }

    .v-image__image--contain {
        top: 9px;
        height: 60%;
    }

    .search-input {
        margin-bottom: 30px !important;
        padding-left: 15px;
        padding-right: 15px;
    }

    .tag {
        position: absolute;
        bottom: 0;
        left: 30px;
        color: white;
        font-size: 14px;
    }

    div.v-responsive.v-image > div.v-responsive__content {
        overflow-y: auto;
    }
}
</style>
