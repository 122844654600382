
const ws = {
  install(vue) {
    vue.config.globalProperties.$fetchSocket = function(ws) {

      ws.onopen = () => {
        vue.config.globalProperties.$store.state.isLoading = false
        ws.send(JSON.stringify({ 'token': vue.config.globalProperties.$store.state.user.token }));
      }

      ws.onmessage = (event) => {
        let data = JSON.parse(event.data);

        if (data.type === "notifications") {
          data.content.map(item => {
            vue.config.globalProperties.$store.dispatch('addNotification', item);
          })

          return
        }

        if (ws.appMessage) {
          ws.appMessage(data);
        }
        
      };

      ws.onerror = () => {
        vue.config.globalProperties.$store.state.isLoading = true
      }
    }

  }
};

export { ws };