<template>
  <v-card class="elevation-0">
    <v-data-table
      v-model="newSelected"
      v-model:page="pagination.page"  
      v-model:sort-by="newSortBy"
      :headers="headers"
      fixed-header
      fixed-footer
      :items="visibleItems"
      :items-per-page="pagination.itemsPerPage"
      :item-value="itemValue"
      multi-sort
      :no-data-text="$t('Common.Data.no-data-text')"
      :no-results-text="$t('Common.Data.no-results-text')"
      :show-select="showSelect"
      :search="search"
      select-strategy="all"
      :class="`grow table--${variant}`"
      @update:sortBy="updateSortBy"
    >
      <template
        v-if="!('header.data-table-select' in $slots)"
        #[`header.data-table-select`]="props"
      >
        <v-checkbox
          :model-value="props.allSelected" 
          :indeterminate="props.someSelected && !props.allSelected"
          indeterminate-icon="mdi-plus-box"
          false-icon="irz-checkbox-blank-outline"
          hide-details
          class="v-data-table__row-selector"
          @update:modelValue="selectAll(props, $event)"
        />
      </template>

      <template
        v-if="!('item.data-table-select' in $slots)"
        #[`item.data-table-select`]="props"
      >
        <v-checkbox
          :model-value="props.isSelected([{value: props.item[itemValue]}])"
          false-icon="irz-checkbox-blank-outline"
          hide-details
          class="v-data-table__row-selector"
          @update:modelValue="toggleSelect(props, $event)"
        />
      </template>
      
      <template
        v-if="isGroupingAvailable"
        #[firstItem]="props"
      >
        <div class="first-item-container">
          <helper-group-prefix
            :is-opened="isOpen(props.item.group_key)"
            :group-key="props.item.group_key"
            :is-header="props.item.isHeader"
            :show-hide-events="showHideItems"
            :count="group(props.item.group_key).length"
          />
          <div
            :style="leftSpaces(props.item)"
          >
            <slot
              :name="firstItem"
              :item="props.item"
            />
          </div>
        </div>
      </template>

      <template
        v-else
        #[firstItem]="{ item }"
      >
        <slot
          v-if="firstItem in $slots"
          :name="firstItem"
          :item="item"
        />

        <span v-else>
          {{ item[headers[0].key] }}
        </span>
      </template>

      <template
        v-for="name in Object.keys($slots).filter(s => s !== firstItem)"
        :key="name"
        #[`${name}`]="{ item }"
      >
        <slot
          :name="name"
          :item="item"
        />
      </template>

      <template
        v-if="!('bottom' in $slots)"
        #bottom="bottom"
      >
        <custom-paginator
          v-if="items?.length > itemsPerPage"
          v-model:propPage="pagination.page"
          v-model:propItemsPerPage="pagination.itemsPerPage"
          v-model:propPageCount="bottom.pageCount"
          :prefix-cookies-name="prefixCookiesName"
          @update:propItemsPerPage="bottom.setItemsPerPage($event)"
          @update:propPage="bottom.pageCount = $event"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
        props: {
            // see also v-data-table api
            showSelect: {
                type: Boolean,
                default: false,
            },
            // see also v-data-table api
            itemValue: {
                type: String,
                default: "",
            },
            // see also v-data-table api
            modelValue: {
                type: Array,
                default() {
                    return [];
                }
            },
            // see also v-data-table api
            headers: {
                type: Array,
                required: true,
            },
            // see also v-data-table api
            items: {
                type: Array,
                required: true,
            },
            // see also v-data-table api
            sortBy: {
                type: Array,
                default() {
                    return []
                }
            },
            // prop for custom row height
            variant: {
                type: String,
                default: 'comfortable',
            },
            // prop for filter params
            search: {
                type: String,
                default: '',
            },
            // see also v-data-table api
            itemsPerPage: {
                type: Number,
                default: 10
            },
            // prefix name for cookies params
            prefixCookiesName: {
                type: String,
                default: '',
            },
            // callback function for generation key for grouped items
            genKey: {
                type: Function,
                default: undefined,
            },
            // filter function to items
            filterItm: {
                type: Function,
                default() {
                    return () => {return true}
                }
            },
            // callback function to toggle tags in filter and combobox
            toggleTagInFilter: {
                type: Function,
                default() {
                    return (e) => e
                }
            },
            // construct head in group
            constructHead: {
                type: Function,
                default() {
                    return () => null
                }
            }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            newSelected: this.modelValue,
            pagination: {
                page: 1,
                itemsPerPage: this.itemsPerPage,
            },
            opened: new Set(),
            visible: new Set(),
            keys: new Map(),
            newSortBy: this.$cookies.getOrDefaultJSON(this.prefixCookiesName + '-sortBy', this.sortBy),
        }
    },
    computed: {
      firstItem() {
        return `item.${this.headers[0].key}`;
      },
      isGroupingAvailable() {
        return !!this.genKey
      },

      filteredItems() {
        if(this.items) {
          return this.items.filter(itm => this.filterItm(itm));
        }
        return [];
      },

      __groupedItems() {
        let itms = [].concat(this.filteredItems).filter(x => x);
        let heads = []
        if(this.isGroupingAvailable) {
          let keys = this.itemKeys(itms);

          for(let i = 0; i < itms.length; i++) {
            let data = keys.get(itms[i].group_key);

            if (this.isGroupOpened(itms[i].group_key)) this.visible.add(itms[i]._id);
            else this.visible.delete(itms[i]._id);

            delete itms[i].isHeader;

            if (data.length > 1) {
              if(data.indexFirst === i) {
                heads.push(structuredClone(itms[i]));
              }
              itms[i].isHeader = false;
            }
          }

          heads.forEach((item) => {
            item.isHeader = true;
            item._id = `head_${item._id}`;
            item.create_time_first = item.create_time;
            keys.get(item.group_key).length++;
          });

          itms = [].concat(itms).concat(heads);

          itms = this.sortedAndGroupedItemsList(itms);

          keys = this.updateIndexFirst(itms, keys);
        }

        return itms;
      },

      headeredGroupedItems() {
        let itms = this.__groupedItems;
        itms.forEach(itm => {
          if (itm.isHeader !== false) {
            this.visible.add(itm._id);
          }
        });

        return itms;
      },

      groupedItems() {
        let itms = this.headeredGroupedItems;
        return itms.map(itm => {
          if (itm.isHeader === true) {
            let group = this.group(itm.group_key, itms);

            itm = this.constructHead(itm, group);
          }

          return itm;
        });
      },

      visibleItems() {
        return this.groupedItems.filter(itm => this.visible.has(itm._id));
      }
    },
    watch: {
      modelValue() {
        this.newSelected = this.modelValue;
      }
    },
    methods: {
        updateSortBy() {
            this.$cookies.setJSON(this.prefixCookiesName + '-sortBy', this.newSortBy);
        },

        toggleSelect(props, event) {
          props.toggleSelect({value: props.item[this.itemValue]});
          if (this.isGroupingAvailable) {
            this.updateSelectedForGroupedList({ element: props.item, selectable: event });
          }
          this.$emit("update:modelValue", this.newSelected);
        },

        itemKeys(itms) {
          let keys = new Map();
          for (let i = 0; i < itms.length; i++) {
            let key = this.genKey(itms[i]);
            itms[i].group_key = key;
            if (keys.has(key)) {
              let data = keys.get(key);
              data.length = data.length + 1;
            }
            else {
              keys.set(key, { length: 1, dateFirst: itms[i].create_time, indexFirst: i });
            }
          }

          this.keys = keys;
          return keys;
        },

        sortedAndGroupedItemsList(itms) {
          return itms.sort((a, b) => {
              if ("isHeader" in a && "isHeader" in b && a.group_key === b.group_key) {
                if (a.isHeader === true || b.isHeader === true) {
                  return b.isHeader - a.isHeader;
                }
                return b.create_time - a.create_time;
              } else {
                return this.keys.get(b.group_key).dateFirst - this.keys.get(a.group_key).dateFirst;
              }
            });
        },
        updateIndexFirst(itms, keys) {
          for (let i = 0; i < itms.length; i++) {
            let itm = itms[i];
            if (itm.isHeader === true) {
              keys.get(itm.group_key).indexFirst = i;
            }
          }
          return keys;
        },

        selectAll(props, isSelected) {
          props.selectAll(!props.allSelected);
          this.newSelected = isSelected ? this.groupedItems.map(itm => itm[this.itemValue]) : [];
          this.$emit("update:modelValue", this.newSelected);
        },

        updateSelectedForGroupedList(selectedElement) {
          const item = selectedElement.element;
          const isSelected = selectedElement.selectable;
          const isHeader = item.isHeader;
          let items;
          let key = item.group_key;

          if (isHeader) {
              let data = this.keys.get(key);
              items = this.groupedItems.slice(data.indexFirst, data.indexFirst + data.length).map(itm => itm[this.itemValue]);
          } else {
            items = [item._id];
          }

          if (isSelected) {
            this.newSelected.push(...items);
          } else {
            let itemsSet = new Set(items);
            this.newSelected = this.newSelected.filter(itm => !itemsSet.has(itm));
          }
          this.newSelected = Array.from(new Set(this.newSelected));
        },
        showHideItems(key) {
          let data = this.keys.get(key);

          for (let i = data.indexFirst; i < data.length + data.indexFirst; i++) {
            if (this.groupedItems[i].isHeader !== false) continue;

            if (this.visible.has(this.groupedItems[i]._id)) {
              this.visible.delete(this.groupedItems[i]._id);
            } else {
              this.visible.add(this.groupedItems[i]._id);
            }
          }
        },

        isOpen(key, itms) {
          let group = this.group(key, itms);
          let isOpened = group
            .filter(itm => this.visible.has(itm._id))
            .length > 1;

          if (isOpened) this.opened.add(key);
          else this.opened.delete(key);

          return isOpened;
        },

        group(key, itms) {
          if (itms === undefined) itms = this.groupedItems;
          let data = this.keys.get(key);
          let group = itms.slice(data.indexFirst, data.indexFirst + data.length);
          group.shift();
          return group;
        },

        leftSpaces(item) {
          if (item.isHeader !== true) {
            if (item.isHeader === false) {
              return "margin-left: 44px"
            } else {
              return "margin-left: 48px"
            }
          }
          return ""
        },

        isGroupOpened(group_key){
          return this.opened.has(group_key)
        }, 

    }
}
</script>

<style scoped>
.v-table.v-data-table.table--compact {
  --v-table-header-height: 45px;
  --v-table-row-height: 40px;
}

.v-table.v-data-table.table--comfortable {
  --v-table-header-height: 45px;
  --v-table-row-height: 48px;
}

.v-data-table__row-selector{
    min-width: 40px;
}

.first-item-container{
    display: flex; 
    align-items: center;
    height: 100%;
}
</style>