// https://vuex.vuejs.org/en/actions.html
import axios from 'axios'
import {fetchData} from "@/plugins/fetchData.js";
import {partialFetchData} from "@/plugins/partialFetchData.js";

function partialParseStream(strm, partial, basket) {
    const chunk = strm.substr(partial.ll, strm.length)
    partial.ll += chunk.length
    partial.buffer += chunk
    const nlen = partial.buffer.match(/\n/g);

    const lines = partial.buffer.split(/\n/).slice(0, nlen.length);

    partial.buffer = partial.buffer.substr(lines.join('\n').length + 1);

    lines.forEach(cc => {
        const c = JSON.parse(cc);
        if (!c[basket.sid]) {
            return
        }
        basket.basket.push(c)
    })
}

export default {
    login({commit}, user) {
        return new Promise((resolve, reject) => {
            // commit('auth_request')
            axios({url: '/api/login/', data: user, method: 'POST'})
                .then(resp => {
                    commit('auth_success', resp.data)

                    commit('changeRoleColor');

                    resolve(resp)
                })
                .catch(err => {
                    // commit('auth_error')
                    localStorage.removeItem('token')
                    reject(err)
                })
        })
    },

    logout({commit}) {
        return new Promise((resolve) => {
            commit('logout')
            commit('changeRoleColor');
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            delete axios.defaults.headers.common['Authorization']
            resolve()
        })
    },

    addInfoMessage({commit}, data) {
        commit('showInfo', data)
    },

    addWarningMessage({commit}, data) {
        commit('showWarning', data)
    },

    addCriticalMessage({commit}, data) {
        commit('showCritical', data)
    },

    getNotifications({commit}) {
        fetchData("post", "/api/notifications/news", {}, {}, () => false).then(resp => {
            commit('updateNotificationsList', resp.data)
        });
    },

    getNotificationRules({commit}) {
        fetchData("post", "/api/notifications/rules").then(resp => {
            commit('updateNotificationRules', resp.data)
        });
    },

    addNotification({commit}, data) {
        commit('pushNewNotification', data)
    },

    getCommonFiles(context, conf) {
        if ("file_type" in conf) {
            switch (conf.file_type) {
                case 'log':
                    fetchData("post", "/api/commonfiles", {file_type: 'log', device_id: conf.dev_id}).then((resp) => {
                        context.commit('setLogs', resp.data)
                    })
                    break;
                case 'backup':
                    fetchData("post", "/api/commonfiles", {file_type: 'backup', device_id: conf.dev_id}).then((resp) => {
                        context.commit('setBackups', resp.data)
                    })
            }   
        } else {
            fetchData("post", "/api/commonfiles/").then(resp => {
                context.commit('setCommons', resp.data)
            });
        }
    },
    getCommons(context) {
        fetchData("post", "/api/commonfiles/").then(resp => {
            context.commit('setCommons', resp.data)
        });
    },
    getCommand(context, conf) {
        fetchData("post", "/api/command/" + conf.command_id).then((resp) => {
                    context.commit('setCommand', resp.data);
                });
    },
    getCommands(context) {
        let partial = {ll: 0, buffer: ''};
        let basket = {basket: [], sid: '_id'};

        const getCommand = (event) => {
            partialParseStream(event.event.target.response, partial, basket);
        }

        const finallyCmnds = () => {
            context.state.commands = [...basket.basket];
        }
        partialFetchData("post", "/api/commands/", {}, {}, getCommand, finallyCmnds, true);
    },
    getTemplates(context) {
        fetchData("post", "/api/templates/").then(resp => {
            context.commit('setTemplates', resp.data)
        });
    },
    getUsers(context) {
        fetchData("post", "/api/users/").then(resp => {
            context.commit('setUsers', resp.data)
        });
    },
    getAdmins(context) {
        fetchData("post", "/api/admins/").then(resp => {
            context.commit('setUsers', resp.data)
        });
    },
    getSuperusers(context) {
        fetchData("post", "/api/superusers/").then(resp => {
            context.commit('setUsers', resp.data)
        });
    },
    getDomains(context) {
        fetchData("post", "/api/domains/").then(resp => {
            context.commit('setDomains', resp.data)
        });
    },
    getDevices(context) {
        let partial = {ll: 0, buffer: ''};
        let basket = {basket: [], sid: 'ident'};

        const getDevice = (event) => {
            if (!event || !event.event || !event.event.target) {
		return;
            }
            partialParseStream(event.event.target.response, partial, basket);
        };

        const finallyDevs = () => {
            context.state.devices = new Map();
            basket.basket.forEach(x => {
                x.tags = x.tags.map(tag => tag.toString());
                context.state.devices.set(x.ident, x)
            });
        };

        partialFetchData('post', '/api/devices/', {}, {}, getDevice, finallyDevs, true)

    },

    getTargetDevices(context, targets) {
        fetchData('post', '/api/devices/multiple/', {targets: targets})
            .then((resp) => {
                resp.data = resp.data.map(device => {device.tags = device.tags.map(tag => tag.toString()); return device})
                context.commit('setTargetDevices', resp.data);
            })
    },

    getEvents(context, also={}) {
        if (also.dev_id) {
            fetchData("post", "/api/events/" + also.dev_id).then((resp) => {
                context.commit('setEvents', resp.data);
                also['successCallback'](resp);
            });
            return
        }
        let partial = {ll: 0, buffer: ''};
        let basket = {basket: [], sid: '_id'};

        const getEvent = (event) => {
            partialParseStream(event.event.target.response, partial, basket)
        };

        const finallyEvts = () => {
            context.state.events = [...basket.basket];
        };

        const _since = also['since'] || -1;
        const _for = also['for'] || 0;
        const _last = also['last'] || 1000;
        const _offset = also['offset'] || 0;
        const successCallback = also['successCallback'] || finallyEvts;
        partialFetchData(
            'post',
            `/api/events/all?since=${_since}&for=${_for}&last=${_last}&offset=${_offset}`,
            {},
            {},
            getEvent,
            successCallback,
            true
        );
    },

    getAccounting(context) {
        fetchData("post", "/api/accounting").then(resp => {
            context.commit('setPolicies', resp.data)
        });
    },

    getTags(context) {
        fetchData("post", "/api/tags/").then(resp => {
            context.commit('setTags', Object.keys(resp.data).map(tag => tag.toString()))

            let arrayTags = [];
            
            for (const [key, value] of Object.entries(resp.data)) {
                arrayTags.push({name: key.toString(), frequency: value});
              }
              
            context.commit('setTagsFrequency', arrayTags);
        });
    },

    getDashboard(context) {
        fetchData("post", "/api/dashboard/").then(resp => {
            context.commit('setDashboard', resp.data)
        });
    },

    getSettings(context) {
        fetchData("post", "/api/settings/all").then(resp => {
            context.commit('setSettings', resp.data)
        });
    },

    getCrontab(context) {
        fetchData("post", "/api/crontab/all").then(resp => {
            context.commit('setCrontab', resp.data)
        });
    },

    AddNewReport({commit}, data) {
        commit('AddReport', data)
    },
    addNewDevice({commit}, data) {
        commit('addDevice', data)
    },
    deleteDevice({commit}, data) {
        commit('deleteDevice', data)
    },
}
