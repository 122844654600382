<template>
  <v-app :theme="nightMode ? 'dark' : 'light'">
    <custom-window-info />

    <v-progress-linear
      v-if="$store.state.isLoading"
      height="10"
      style="margin: 0; position: fixed; z-index: 101"
      :color="$store.state.color"
      :indeterminate="$store.state.isLoading"
    />

    <core-toolbar v-if="isLoggedIn && !factoryPassword" />
    <core-drawer />
    <core-view />
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";

export default {

    data() {
        return {
            lastAction: new Date().getTime(),
        }
    },

    computed: {
        ...mapGetters(["isLoggedIn", "factoryPassword"]),

        nightMode() {
            return this.$store.state.isDarkTheme;
        },
    },

    created() {
        this.$i18n.locale = this.$cookies.getOrDefaultJSON('locale', 'ru');

        this.change();

        window.addEventListener(
            "beforeunload",
            this.closeApp,
        );

        this.updateTimer();
        for (const evt of ["mousemove", "mousedown", "keypress"]) {
            window.addEventListener(
                evt,
                this.updateTimer
            );
        }
    },

    methods: {
        change() {
            this.$store.commit('changeRoleColor');
        },

        closeApp() {
            // this.$store.commit('logout');
        },

        updateTimer() {
            const l = new Date().getTime();
            if (l - this.lastAction > 60000) {
                this.lastAction = l;
                this.$cookies.setJSON("lastAction", this.lastAction);
            }
        }
    },

}

</script>

<style lang="scss">
@import './styles/index.scss';

html {
    overflow: auto !important;
}

</style>
