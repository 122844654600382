<template>
  <v-row class="filtered-combobox">
    <v-col
      :class="['v-card v-card--variant-elevated search-box box', focused ? 'search-box--focused' : '']"
      style="display: flex; align-items: center; justify-content: space-between"
      @click="$refs.search.focus()"
    >
      <v-icon
        class="mx-2"
        size="18"
      >
        irz-magnify
      </v-icon>
      <div
        ref="chip_inputbox" 
        class="box"
        @click="$refs.search.focus()"
      >
        <div
          v-if="box"
          style="display: flex; flex-wrap: wrap; align-items: center; width: 100%; overflow-y: auto; height: 100%"
        >
          <v-chip
            v-for="tag in selected"
            :key="tag"
            class="small-chip mr-1 my-1"
            style="height: 18px !important"
            variant="outlined"
            size="x-small"
            @mouseenter="chip_hover[tag] = true"
            @mouseleave="chip_hover[tag] = false"
            @click="delTag(tag)"
          >
            {{ tag }}
          </v-chip>

          <helper-shadow-input
            ref="search"
            style="min-width: 30%"
            :tags="tags" 
            @update:focus="focused = $event"
            @add:tag="addTag($event)"
          />
        </div>

        <div
          v-else
          style="width: 100%"
        >
          <input
            ref="search" 
            style="width: 100%; height: 20px !important; line-height: 18px; border: 0; outline: 0"
            :placeholder="$t('Common.Data.search')"
            @change="$emit('update:search', $event.target.value)"
          >
        </div>

        <v-btn
          v-show="filterTags"
          icon="irz-close"
          variant="text"
          size="24"
          @click="clearTags"
        />
      </div>
    </v-col>

    <v-col
      v-if="box"
      class="v-col-auto v-card v-card--variant-elevated ml-1"
      style="display: flex; align-items: center; padding: 0"
    >
      <v-menu :close-on-content-click="false">
        <template #activator="{ props }">
          <v-btn
            icon="irz-tune-variant"
            v-bind="props"
            variant="text" 
            style="height: 100%; width: 40px; border-radius: inherit"
          />
        </template>

        <v-card
          style="width: 80px; transform: translate(-20px, 5px)"
          :theme="$store && $store.state && $store.state.isDarkTheme ? 'dark' : 'light'"
        >
          <v-radio-group
            v-model="behaviour"
            hide-details
            @update:modelValue="$emit('update:filterBehaviour', $event)"
          >
            <v-radio
              :label="$t('Common.Data.and')"
              value="and"
            />
            <v-radio
              :label="$t('Common.Data.or')"
              value="or"
            />
          </v-radio-group>

          <v-checkbox
            v-model="isNot"
            :label="$t('Common.Data.not')"
            hide-details
            @update:modelValue="$emit('update:filterIsNot', $event)"
          />
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'FilteredCombobox',

    props: {
        prefixCookiesName: {
            type: String,
            required: false,
            default: () => ''
        },
        box: Boolean,
        tags: {
            type: Set,
            default: new Set(),
            required: true
        },

        filterTags: {
            type: Set,
            default: new Set(),
        },
        filterBehaviour: {
            type: String,
            validator(value) {
                return ['and', 'or'].includes(value);
            },
            default: 'and'
        },
        filterIsNot: {
            type: Boolean,
            default: false
        }
    },

    emits: ['update:filterTags', 'update:filterBehaviour', 'update:search', 'update:filterIsNot'],

    data() {
        return {
            focused: false,
            chip_hover: {},
            isNot: false,
            selected: new Set(),
            behaviour: 'and'
        }
    },
    computed: {
      cookiesPrefix() {
        return this.prefixCookiesName || this.$route.name;
      }
    },
    watch: {
        behaviour(val) {
            this.$cookies.setJSON(`${this.cookiesPrefix}-filterBehaviour`, val);
        },
        selected: {
            handler(val) {
              this.$cookies.setJSON(`${this.cookiesPrefix}-filterTags`, Array.from(val));
            },
            deep: true
        },
        isNot(val) {
            this.$cookies.set(`${this.cookiesPrefix}-filterIsNot`, val)
        },
        filterBehaviour(val) {
            this.behaviour = val;
        },
        filterIsNot(val) {
            this.isNot = val;
        },
        filterTags(val) {
            this.selected = val;
        }
    },

    mounted() {
        this.selected = this.filterTags;
        this.behaviour = this.filterBehaviour;
        if(this.selected?.size === 0) {
            let cookiesFilterTags = this.$cookies.getOrDefaultJSON(`${this.cookiesPrefix}-filterTags`, []).filter(x => x);
            for(let tag of cookiesFilterTags) {
                this.selected.add(tag);
            }  

            this.behaviour = this.$cookies.getOrDefaultJSON(`${this.cookiesPrefix}-filterBehaviour`, 'and');
            this.$emit('update:filterBehaviour', this.behaviour);

            this.isNot = this.$cookies.getOrDefault(`${this.cookiesPrefix}-filterIsNot`, false) === 'true';
            this.$nextTick(() => this.$emit('update:filterIsNot', this.isNot))
        }
    },

    methods: {
      focus() {
        this.$refs.search.focus()
      },
      addTag(t) {
       	this.selected.add(t)
        this.$emit('update:filterTags', this.selected)
      },
      delTag(t) {
        this.selected.delete(t);
        this.$emit('update:filterTags', this.selected)
      },
      clearTags() {
        if (this.box) {
            this.$refs.search.clear()
            this.selected.clear()
            this.$emit('update:filterTags', this.selected)
        } else {
            this.$refs.search.value = ''
            this.$emit('update:search', '')
        }
      }
    },
  }
</script>

<style scoped>
.filtered-combobox {
  border-radius: 5px;
}
.box {
  padding: 4px;
  cursor: text;
  width: 100%; 
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
.small-chip .v-chip__close .irz-close-circle {
  margin-top: 1px;
}
.v-theme--light .filtered-combobox .search-box:hover {
  background: #dbdbdb;
}
.v-theme--dark .filtered-combobox .search-box:hover {
  background: #323232;
}
.v-theme--light .filtered-combobox .search-box--focused {
  background: #d3d3d3 !important;
}
.v-theme--dark .filtered-combobox .search-box--focused {
  background: #454545 !important;
}
.box .v-btn .irz-icon {
  display: inline-grid;
  height: 24px;
}
</style>

