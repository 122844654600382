<template>
  <div
    :class="classes"
    class="v-offset"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    offset: {
      type: [Number, String],
      default: 0
    }
  },

  computed: {
    classes () {
      return {
        'v-offset--full-width': this.fullWidth
      }
    },
  }
}
</script>

<style>

</style>
