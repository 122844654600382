<template>
  <helper-table
    :model-value="newSelected"
    :variant="variant"
    :items="items"
    :headers="headers"
    :show-select="showSelect"
    item-value="_id"
    :sort-by="sortBy"
    :prefix-cookies-name="prefixCookiesName"
    :filter-itm="filterItmByTags"
    :gen-key="genKey"
    :toggle-tag-in-filter="toggleTagInFilter"
    :construct-head="constructHead"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template
      v-for="(_, name) in $slots"
      :key="name"
      #[`${name}`]="{ item }"
    >
      <slot
        :name="name"
        :item="item"
      />
    </template>
  </helper-table>
</template>

<script>
    export default {
        props: {
            // prop for custom row height
            variant: {
                type: String,
                default: 'comfortable',
            },
            search: {
                type: Object,
                required: false,
                default() {
                    return {
                        tags: new Set(),
                        filterTags: new Set(),
                        filterBehaviour: 'and'
                    }   
                }
            },
            items: {
                type: Array,
                required: true,
            },
            showSelect: {
                type: Boolean,
            },
            prefixCookiesName: {
                type: String,
                required: false,
                default: "",
            },
            genKey: {
                type: Function,
                required: false,
                default() {
                    return () => {return "defaultKey"}
                }
            },
            filterItm: {
                type: Function,
                required: false,
                default() {
                    return (itms) => {return itms}
                }
            },
            modelValue: {
                type: Array,
                required: false,
                default() {
                    return [];
                }
            },
            headers: {
                type: Array,
                required: true
            },
            toggleTagInFilter: {
                type: Function,
                required: false,
                default() {
                    return (e) => e
                }
            },
            constructHead: {
                type: Function,
                required: false,
                default() {
                    return () => null
                }
            },
            sortBy: {
              type: Array,
              required: false,
              default() {
                  return []
              }
          },
        },
        emits: ['update:modelValue'],
        data() {
            return {
                newSelected: this.modelValue,
            }
        },
        watch: {
            modelValue() {
                this.newSelected = this.modelValue;
            }
        },
        methods: {
            updateSelect() {
                this.$emit("update:modelValue", this.newSelected);
            },

            filterItmByTags(item) {
                if (!this.filterItm(item)) return false;

                const and = this.search.filterBehaviour !== 'or';
                const isNot = this.search.filterIsNot;
                const checkStatus = (pair, evt) => pair[0] === '@status' && (pair[1] === 'unread') === !evt.read
                const filter = (tag, evt) => !tag ||
                    tag.startsWith('@author') && tag.split('=')[1].includes(evt.author_id) ||
                    tag.startsWith('@type=') && tag.split('=')[1].includes(evt.type) ||
                    tag.startsWith('@read=') && tag.split('=')[1].includes(evt.read) ||
                    tag.startsWith('@') && checkStatus(tag.split('='), evt);

                return item && (
                    this.search.filterTags.length === 0 ||
                    isNot !== [].concat(...this.search.filterTags)
                        .map(tag => filter(tag, item))
                        .reduce((a, b) => and ? a && b : a || b, and)
                );
            },
        }
    }
</script>