// https://vuex.vuejs.org/en/getters.html

import  { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const checkPlugin = plugin => (state => (state?.user?.plugins?.includes(plugin)));

let hasNotify     = checkPlugin('notification')
let hasAccounting = checkPlugin('accounting')

export default {
  // isLoggedIn: state => !!state.user.token,
  isLoggedIn: state => state.user.token !== undefined,
  // authStatus: state => state.status,
  getDarkTheme: state => state.isDarkTheme,
  getRoleColor: state => state.color,
  scripts: state => state.scripts,
  packages: state => state.packages,
  firmwares: state => state.firmwares,
  command: state => state.command,
  commands: state => state.commands,
  templates: state => state.templates,
  users: state => state.users,
  domains: state => state.domains,
  devices: state => state.devices,
  targetDevices: state => state.targetDevices,
  events: state => state.events,
  tags: state => state.tags,
  tagsFrequency: state => state.tagsFrequency,
  dashboard: state => state.dashboard,
  crontab: state => state.crontab,
  notifications: state => hasNotify ? state.notifications : [],
  notificationRules: state => hasNotify ? state.notificationRules : [],
  policies: state => hasAccounting ? state.policies : [],

  isRoot: state => (state.user.role === 1 && state.user.name === "root"),
  isSuperuser: state => (state.user.role === 1),
  isAdmin: state => (state.user.role === 2),
  isHelper: state => (state.user.role === 3),
  isStaff: state => (state.user.role === 4),

  hasNotify:        hasNotify,
  hasAccounting:    hasAccounting,
  factoryPassword:  state => hasAccounting(state) && state.user.factory_password === true,

  settings: state => {
    let cookieDash = cookies.get('dash');
    if(cookieDash){
      state.settings.dash ||= {};
      let dash = {};
      for (const key of Object.keys(state.settings.dash)) {
        dash[key] = cookieDash[key];
      }
      state.settings.dash = dash;
      return state.settings;
    }
    if(Object.keys(state.dashboard).length) {
      state.settings.dash = {};
      for (let key in state.dashboard) {
        state.settings.dash[key] = true;
      }
      cookies.set('dash', JSON.stringify(state.settings.dash));
    }
    return state.settings;
  },

  passwordExpiredSoon: state => {
    if(!hasAccounting(state)) {
      return false;
    }

    if(!state.user.password_expire_date)
      return false;

    const now = (new Date().getTime());
    return state.user.password_expire_date - now < (15 * 86400 * 1000);

  },

}
