<template>
  <v-label>This is a user monitor</v-label>
</template>

<script>
export default {
    name: "User"
}
</script>

<style scoped>

</style>