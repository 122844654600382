<template>
  <v-container :fluid="true">
    <v-form v-model="valid">
      <v-row>
        <v-col
          cols="12"
          md="6"
          xl="4"
        >
          <material-card
            variant="header"
            :title="$t('Settings.List.dashboard')"
          >
            <v-container>
              <v-row>
                <v-col
                  v-for="(v, k) in config('dash')"
                  :key="k"
                  cols="6"
                  md="4"
                  style="padding: 0"
                >
                  <v-checkbox
                    v-model="config('dash')[k]"
                    :label="$t('Common.Dashboard.' + k)"
                    hide-details
                    true-icon="irz-eye"
                    false-icon="irz-eye-off"
                    @change="updateDash"
                  />
                </v-col>
              </v-row>
            </v-container>
          </material-card>
        </v-col>

        <v-col
          v-if="$store.getters.isAdmin"
          cols="12"
          md="6"
          xl="4"
        >
          <material-card
            variant="header"
            :title="$t('Settings.List.list')"
          >
            <v-container style="margin-bottom: 8px">
              <v-row>
                <v-col
                  v-for="(st, k) in config('status')"
                  v-show="k !== 'critical'"
                  :key="k"
                >
                  <v-text-field
                    v-if="k !== 'critical'"
                    v-model="st.rule"
                    :label="st.name + ' (' + $t('Settings.List.minutes') + ')'"
                    :rules="statusRules(st)"
                    type="number"
                    min="15"
                    max="10080"
                    variant="solo-filled"
                    :hide-detailes="false"
                  />
                </v-col>
              </v-row>
            </v-container>
          </material-card>
        </v-col>

        <v-col
          v-if="$store.getters.isAdmin"
          cols="12"
          md="6"
          xl="4"
        >
          <material-card
            variant="header"
            :title="$t('Settings.List.copies')"
          >
            <v-container style="margin-bottom: 8px">
              <v-row>
                <v-col
                  v-for="k in ['backup', 'log']"
                  :key="k"
                  cols="6"
                >
                  <v-text-field
                    v-model="config(k).copies"
                    type="number"
                    min="1"
                    max="100"
                    variant="solo-filled"
                    :hint="$t('Settings.List.max_copies')"
                    :rules="[v => !v ||
                      1 <= parseInt(v) && parseInt(v) <= 100 ||
                      $t('Settings.List.max_copies')]"
                    :label="$t('Settings.List.' + k)"
                    @change="changeCopies($event.target._value, k)"
                  />
                </v-col>
              </v-row>
            </v-container>
          </material-card>
        </v-col>

        <v-col
          v-if="$store.getters.isAdmin"
          cols="12"
        >
          <v-btn
            :disabled="!valid"
            :color="$store.state.color"
            @click="saveData"
          >
            {{ $t('Common.Data.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <div>
      <v-row
        v-if="$store.getters.isAdmin"
        justify="center"
      >
        <v-col
          v-for="k in ['backup', 'log']"
          :key="k"
          cols="12"
        >
          <material-card
            variant="header--search--action-add"
            :title="$t('Settings.List.' + k + '_configuration')"
            @add="openRule('new', k)"
            @update:search="search[k] = $event"
          >
            <helper-table
              v-model:page="pagination[k].page"
              :headers="configHeaders"
              :search="search[k]"
              :items="[].concat(crontab(k))"
              :prefix-cookies-name="pagination[k].prefixCookiesName"
            >
              <template #item="{ item }">
                <tr>
                  <td>
                    <v-checkbox
                      v-model="item.active"
                      hide-details
                      @click="activateRule(item._id, k, item.active)"
                    />
                  </td>
                  <td>
                    <span
                      class="clickable"
                      @click="openRule(item._id, k)"
                    >
                      {{ item.name }}
                    </span>
                  </td>
                  <td>{{ getRegularity(item.regular) }}</td>
                  <td v-if="item.active_for === 'group'">
                    <v-chip
                      v-for="tag in item.search.filterTags"
                      :key="tag"
                      class="small-chip mr-1"
                      variant="outlined"
                    >
                      {{ tag }}
                    </v-chip>
                  </td>
                  <td v-else>
                    <v-chip
                      class="small-chip text-bg-primary mr-1"
                      variant="outlined"
                    >
                      all
                    </v-chip>
                  </td>
                </tr>
              </template>
            </helper-table>
          </material-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {fetchData} from "@/plugins/fetchData.js";
import {required} from "@/plugins/fieldRules";

export default {
    data() {
        return {
            valid: false,
            pagination: {
                backup: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: 'ident',
                    prefixCookiesName: "SettingsBackup"
                },
                log: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: 'ident',
                    prefixCookiesName: "SettingsLog"
                }
            },

            search: {
                backup: '',
                log: ''
            },
        };
    },

    computed: {

        config() {
            return k => {
                return this.$store.getters.settings[k] || [];
            }
        },

        crontab() {
            return k => {
                return this.$store.state.crontab.filter(x => x.type === k);
            }
        },

        devices() {
            return this.$store.state.devices;
        },

        statusRules() {
            const getNextRule = cur => cur.name ? this.config('status')[cur.name.split(' ', 3).pop()].rule : 2**62;
            const checkBetween = (cur, next) => (15 <= cur && cur <= Math.min(10080, next));
            return st => [
                this.required, 
                v => !!v && checkBetween(parseInt(v), parseInt(getNextRule(st))) || 
                    this.$t('Common.Rules.mail_rule_2')
            ];
        },

        activities() {
            return [
                {title: this.$t('Settings.List.none'), value: 'none'},
                {title: this.$t('Settings.List.group'), value: 'group'},
                {title: this.$t('Settings.List.all'), value: 'all'},
            ]
        },

        regularities() {
            return [
                {title: this.$t('Settings.List.every_day'),      value: 1},
                {title: this.$t('Settings.List.every_N_days').replace('#1', 2), value: 2},
                {title: this.$t('Settings.List.every_N_days').replace('#1', 3), value: 3},
                {title: this.$t('Settings.List.every_week'),     value: 7},
                {title: this.$t('Settings.List.every_2_weeks'),  value: 14},
                {title: this.$t('Settings.List.every_month'),    value: 30},
                {title: this.$t('Settings.List.every_3_months'), value: 90},
            ]
        },

        headers() {
            return [
                {title: this.$t('Settings.List.name'), sortable: false, align: "left", key: "name"},
                {title: this.$t('Settings.List.rule'), sortable: false, key: "rule"},
            ]
        },

        configHeaders() {
            return [
                {title: this.$t('Settings.List.active'), sortable: false, align: "left", key: "active"},
                {title: this.$t('Settings.List.name'), sortable: true, align: "left", key: "name"},
                {title: this.$t('Settings.List.regular'), sortable: false, align: "left", key: "regularity"},
                {title: this.$t('Common.Dashboard.tags'), sortable: false, align: 'left', key: 'tags'},
            ]
        },
    },

    mounted() {
        this.$store.dispatch('getSettings');
        if (this.$store.getters.isAdmin) {
            this.$store.dispatch('getCrontab');
        }

        if (!this.devices.length) {
            this.$store.dispatch("getDevices");
        }

        this.$store.dispatch("getTags");
    },

    methods: {
        required,

        updateDash() {
            this.$cookies.setJSON("dash", this.config('dash'));
        },

        saveData() {
            this.updateDash();

            if (this.$store.getters.isAdmin) {
                let b = {...this.$store.getters.settings};
                delete b['dash'];

                fetchData("patch", "/api/settings/domains-all", b).then(() => {
                    this.$store.dispatch('addCriticalMessage', this.$t('Custom.Push.updated'))
                });
            }
        },

        toggleTagInFilter(tag, key) {
            if (!tag) return;
            tag = tag.toString();

            this.config(key)['filterTags'] = this.config(key).filterTags ? this.config(key).filterTags : new Set();

            if (this.config(key).filterTags.has(tag)) {
                this.config(key).filterTags.delete(tag);
            } else {
                this.config(key).active = 'group';
                this.config(key).filterTags.add(tag);
            }
        },

        openRule(task_id, c) {
            this.$router.push({name: "task-rule", params: {class: c, task_id: task_id}});
        },

        activateRule(rule_id, c, active) {
            fetchData("patch", "/api/crontab/" + c + "/" + rule_id, {active: !active})
                .then(() => {
                    this.$store.dispatch('addInfoMessage', this.$t('Custom.Push.updated'))
                });
        },

        changeCopies(prev_cps, k) {
            if (this.config(k).copies && this.config(k).copies < prev_cps) {
                this.$store.dispatch('addInfoMessage', this.$t('Settings.Task.remove_old_versions_warning'));
            }
        },

        addZeros(h) {
            h = String(h);
            while (h.length < 2) h = '0' + h;
            return h;
        },

        shiftedArrayPosition(array, pos, shift) {
            shift = shift % array.length;
            let poz = (pos - shift + array.length) % array.length;
            while (array[pos] === '_' && poz !== pos) pos = (pos + shift) % array.length;
            return pos;
        },

        getRegularity(regular) {
            return this.$t('Settings.Task.' + regular.basic) +
                (
                    regular.basic === 'weekly' && regular.options?.weeks ?
                        ' (' + regular.options.weeks.sort().map(x => this.$tm('Settings.Task.month_weeks')[x]).join(', ') + ')' : ''
                ) +
                (
                    regular.options?.days ?
                        ': ' + [].concat(regular.options.days)
                                   .map(x => this.shiftedArrayPosition(this.$tm('Settings.Task.week_days'), x, 7))
                                   .sort()
                                   .map(x => this.$tm('Settings.Task.week_days')[x]).join(', ') + '. ' : ''
                ) +
                (
                    regular.time ? this.$t('Settings.Task.at') + ' ' + this.$getTime(regular.time) : ''
                )
        },
    }
};
</script>
