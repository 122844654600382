export default {
  dark: false,
  colors: {
    // root: '#EF5350',
    // admin: '#2196F3',
    // assistant: '#00BCD4',
    // operator: '#4CAF50',
    root: '#0052cc', //'#b2344d',
    admin: '#0052cc', //'#b8716e',
    assistant: '#0052cc', //'#db84ae',
    operator: '#0052cc', //'#e57d7a',

    dusk: '#212121',
    dawn: '#757575',

    error: '#D50000',
    success: '#689F38',
  },
  variables: {
    'medium-emphasis-opacity': 0.8,
    'disabled-emphasis-opacity': 0.5,
    'border-opacity': 0.05,
    'hover-opacity': 0.12,
    'focus-opacity': 0.16,

    'theme-background': '238,242,248',
  }
}
