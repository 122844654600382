<template>
  <v-form v-model="model.valid">
    <v-row>
      <v-col
        cols="12"
        md="10"
      >
        <v-text-field
          v-model="model.discordWebhookUrl" 
          variant="solo-filled"
          :rules="[required]"
          hint="https://discord.com/api/webhooks/<THREAD_ID>/<TOKEN>"
          hide-details="auto"
        >
          <template #label>
            {{ $t('Notifications.Transports.webhook_url') }}
            <span class="ml-1 mb-1">*</span>
          </template>
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="10"
      >
        <v-text-field
          v-model="model.discordUsername" 
          variant="solo-filled"
          :label="$t('Notifications.Transports.display_name')"
          :hint="$root.appName"
          hide-details="auto"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import {required} from "@/plugins/fieldRules";

export default {

    props: {
        modelValue: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    emits: [ "update:modelValue" ],

    computed: {
        model: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    },
    methods: {required},
    
}

</script>
