/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import {createRouter, createWebHistory} from 'vue-router'
import {store} from '@/store'
import {i18n} from '@/i18n'
import Meta from 'vue-meta'

// Routes
import paths from './paths'

function route (path, view, name, meta) {
  return {
    name: name || view,
    meta,
    path,
    props: true,
    component: (resolve) => import(
      `@/views/${view}.vue`
    ).then(resolve)
  }
}

function redirect(state) {
  return !state.isLoggedIn  ? '/login' :
          state.factoryPassword ? '/profile' :
          state.isSuperuser ? '/accounting' :
                              '/dashboard';
}

// Create a new router
const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: paths.map(path => route(path.path, path.view, path.name, path.meta)).concat([
    { path: '/:_(.*)', redirect: () => { return redirect(store.getters) } }
  ]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { left: 0, top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const msg = store.state.info_msg.length > 0 ? [store.state.info_msg.shift()] : [];
  store.state.info_msg_cached = store.state.info_msg.length > 0 ? store.state.info_msg : msg;
  store.state.info_msg = [];

  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      // if ((!from || !from.path || from.path === '/') && !['accounting', 'dashboard', 'profile'].includes(to.name)) {
      //   next(redirect(store.getters))
      //   return
      // }
      if(store.getters.factoryPassword && to.path !== '/profile') {
        store.dispatch('addInfoMessage', i18n.global.t('Custom.Push.access_denied'));
        next(redirect(store.getters))
      } else if(to.meta.roles.includes(parseInt(store.state.user.role))) {
        next()
      } else {
        store.dispatch('addInfoMessage', i18n.global.t('Custom.Push.access_denied'));
        next(from)
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  for (const msg of store.state.info_msg_cached) {
    store.state.info_msg.push(msg);
  }
  store.state.info_msg_cached = [];
})

router.backOrGo = function(defaultRoute) {
    if (router.options.history.state.back) {
        router.back();
    } else {
        router.push(defaultRoute);
    }    
}

export { router, Meta }
