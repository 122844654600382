<template>
  <div
    ref="inputbox"
    class="input-box"
  >
    <input
      ref="hint"
      v-model="hint"
      disabled
      class="p-0"
      style="padding: 10px; opacity: 0.4; background: transparent; width: auto; height: 20px !important; line-height: 18px"
    > 
    <input
      ref="input"
      v-model="input"
      class="p-0"
      style="position: absolute; padding: 10px; background: transparent; line-height: 18px"
      :placeholder="$t('Common.Data.search')"
      @focusin="$emit('update:focus', true)"
      @focusout="$emit('update:focus', false)"
      @keypress="inputSymb"
      @keydown.tab.prevent="input = hint"
      @keydown.enter="addTag"
      @keydown.space="addTag"
    >
  </div>
</template>

<script>
  export default {
    name: 'ShadowInput',

    props: {
        tags: {
            type: Set,
            default: new Set(),
            required: true
        },

        filterTags: {
            type: Set,
            default: new Set(),
        },
    },

    emits: ['update:filterTags', 'update:focus', 'add:tag'],

    data() {
      return {
        hint: '',
        input: '',
        selected: new Set(),
      }
    },

    watch: {
      input(val) {
        this.hint = val;
        if (!val) {
          return;
        }
        for (let i of this.tags) {
          if (i.startsWith(val)) {
            this.hint = i
            break
          }
        }
      },
      filterTags() {
        this.resize();
      }
    },

    mounted() {
      this.selected = this.filterTags;
      this.resize()
      this.$nextTick(() => {
        window.addEventListener('resize', this.resize)
      })
    },

    updated() {
        this.resize();
    },

    beforeUnmount() {
      window.removeEventListener('resize', this.resize)
    },

    methods: {
      focus() {
        this.$refs.input.focus()
      },

      clear() {
        this.$refs.input.value = '';
      },

      addTag() {
        if (!this.input) return;
        
        this.selected.add(this.input)
        this.$emit('add:tag', this.input)
        this.input = ''
      },

      inputSymb(ev) {
        if (!ev.key.match(/[а-яА-Яa-zA-Z0-9_\-\@\=]/)) ev.preventDefault()
      },

      resize() {
        let cRect = this.$refs.hint.getBoundingClientRect();

        this.$refs.input.style.height = cRect.height + 'px'
        this.$refs.input.style.width = cRect.width + 'px'
      },
    },
  }
</script>

<style>
  .input-box {
    height: auto;
    display: flex;
  }

  .input-box input {
    outline: none;
  }
</style>

