<template>
  <div
    v-bind="$attrs"
    :style="styles"
  >
    <helper-offset
      v-if="hasOffset"
      :inline="inline"
      :full-width="fullWidth"
      :offset="offset"
    >
      <slot
        v-if="$slots.offset"
        name="offset"
      />

      <div v-else>
        <v-row
          class="mx-0"
          style="margin-top: -20px"
        >
          <v-col
            v-if="isChildOf('search')"
            cols="auto"
            class="mr-5 mt-2" 
            style="display: flex; align-items: center; min-width: 422px; max-width: 80%"
          >
            <helper-filtered-combobox
              ref="search"
              :box="is('search-box')"
              :tags="search.tags"
              :filter-tags="search.filterTags"
              :filter-behaviour="search.filterBehaviour"
              :filter-is-not="search.filterIsNot"
              :prefix-cookies-name="prefixCookiesName"
              @update:search="is('search-box') ? updateSearch('filterTags', $event) : $emit('update:search', $event)"
              @update:filterTags="updateSearch('filterTags', $event)"
              @update:filterBehaviour="updateSearch('filterBehaviour', $event)"
              @update:filterIsNot="updateSearch('filterIsNot', $event)"
            />
          </v-col>

          <v-col
            v-if="isChildOf('action')"
            cols="auto"
            class="action-box mt-2"
          >
            <slot
              v-if="$slots.actions"
              name="actions"
            />
            
            <v-btn
              v-else-if="is('action-add')"
              class="action-button v-card v-card--variant-elevated p-3"
              :color="$store.state.isDarkTheme ? undefined : $store.state.color"
              variant="flat"
              prepend-icon="irz-plus"
              @click="add()"
            >
              {{ $t('Common.Data.add') }}
            </v-btn>
          </v-col>


          <v-spacer v-if="is('action-reload') || is('action-arch')" />

          <v-col
            v-if="is('action-arch')"
            cols="auto"
            class="action-box mt-2"
          >
            <v-btn
              class="action-button-mini ml-3"
              variant="text"
              icon="irz-zip-outline"
              @click="archive()"
            />
          </v-col>

          <v-col
            v-if="is('action-reload')"
            cols="auto"
            class="action-box"
          >
            <v-btn
              class="action-button-mini ml-3"
              variant="text"
              icon="irz-reload"
              @click="reload()"
            />
          </v-col>
        </v-row>
      </div>
    </helper-offset>

    <v-card style="margin-top: 22px">
      <helper-offset
        v-if="is('header')"
        :inline="inline"
        :full-width="fullWidth"
        :offset="offset"
      >
        <slot
          v-if="$slots.offset"
          name="offset"
        />

        <div v-else>
          <slot
            v-if="$slots.header"
            name="header"
          />
 
          <v-row
            v-else
            class="ml-2 py-0"
          >
            <v-col cols="auto">
              <slot
                v-if="$slots.textbox"
                name="textbox"
              />

              <v-row
                v-else
                style="padding: inherit; display: flex; align-items: center"
              >
                <v-col
                  cols="auto"
                  :class="`pb-0 ${text || isChildOf('search') ? '' : 'pt-1'}`"
                >
                  <span>
                    <h4
                      :class="['title', 'font-weight-bold'].concat(text ? ['mb-2'] : ['mt-1'])"
                      v-text="title"
                    />
                    <h5
                      v-if="text"
                      class="category font-weight-regular"
                      v-text="text"
                    />
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </helper-offset>

      <v-card-text class="pt-0">
        <slot />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export function variantValidation(value){
  let checkValue = value.match(/^(header)$/) || 
                  value.match(/^(header--)?search(-box)?(--action)?(--action-(add|arch|reload))?$/) || 
                  value.match(/^(header--)(action--)?(action|action-(add|arch|reload))$/);
  
  return !!checkValue
}

export default {

    props: {
        prefixCookiesName: {
          type: String,
          required: false,
          default: () => ''
        },
        archive: {
            type: Function,
            required: false,
            default: () => {return undefined}
        },
        elevation: {
            type: [Number, String],
            default: 10
        },
        inline: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        offset: {
            type: [Number, String],
            default: 24
        },
        title: {
            type: String,
            default: undefined
        },
        text: {
            type: String,
            default: undefined
        },
        variant: {
            type: String,
            validator(value) {
                return value === undefined || variantValidation(value)
            },
            default: undefined,
        },
        search: {
            type: [String, Object],
            default: ''
        },
    },

    emits: ['update:search', 'add', 'reload'],

    data() {
        return {
            searchActive: false,
            windowWidth: 900
        }
    },

    computed: {
        hasOffset() {
            return this.$slots.offset ||
                this.isChildOf('search') || 
                this.isChildOf('action')
        },
        styles() {
            if (!this.hasOffset) return null

            return {
                marginBottom: `${this.offset}px`,
                marginTop: `${this.offset}px`
            }
        },
        is() {
            return k => {
                return this.variant && this.variant.split('--').includes(k)
            }
        },
        isChildOf() {
            return k => {
                return this.variant && this.variant.split('--').map(x => x.startsWith(k)).reduce((a, b) => a || b, false)
            }
        }
    },

    mounted() {
        this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width;
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },

    unmounted() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {

        onResize() {
            this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width
        },

        searchActivate() {
            this.filterTags.clear();
            this.searchActive = !this.searchActive;
            setTimeout(() => { this.$refs.search.focus() }, 100)
        },

        updateSearch(key, value) {
            if (this.is('search')) return;
            let o = this.search;
            o[key] = value;
            this.$emit("update:search", o);
        },

        add() {
            this.$emit("add");
        },

        reload() {
            this.$emit("reload");
        }
    }
};
</script>
