import  { useCookies } from "vue3-cookies";

const { cookies } = useCookies();
const exportCookies = {
    install(vue) {
        vue.config.globalProperties.$cookies = cookies;
        let ver = cookies.get('version');
        if (!ver) {
             for (const key of cookies.keys()) {
                 cookies.remove(key);
             }
             cookies.set('version', '1');
        }

        vue.config.globalProperties.$cookies.getOrDefault = function(cookiePath, defaultValue) {
            let cookie = cookies.get(cookiePath);
            return cookie ? cookie : defaultValue;
        }
        vue.config.globalProperties.$cookies.getOrDefaultJSON = function(cookiePath, defaultValue) {
            try {
                let cookie = JSON.parse(cookies.get(cookiePath));
                return cookie ? cookie : defaultValue;
            } catch (ignored) {
                return defaultValue;
            }
        }
        vue.config.globalProperties.$cookies.setJSON = function(cookiePath, value) {

            cookies.set(cookiePath, JSON.stringify(value));
        }
    }
}

export {exportCookies};
