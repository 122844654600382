<template>
  <helper-table
    :search="search"
    :variant="variant"
    :items="users"
    :headers="headers"
    :prefix-cookies-name="prefixCookiesName"
  >
    <template
      v-for="(_, name) in $slots"
      :key="name"
      #[`${name}`]="{ item }"
    >
      <slot
        :name="name"
        :item="item"
      />
    </template>
  </helper-table>
</template>

<script>
import {mapState} from "vuex";

export default{
  props: {
    // prop for custom row height
    variant: {
      type: String,
      default: 'comfortable',
    },
    search: {
      type: String,
      required: false,
      default: ''
    },
    headers: {
      type: Array,
      required: true
    },
    prefixCookiesName: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState(["users"]),
  }
}
</script>