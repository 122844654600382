<template>
  <v-container :fluid="true">
    <v-row
      justify="center"
      wrap
    >
      <v-col
        cols="12"
        md="12"
      >
        <material-card
          variant="header--search--action-add"
          :title="$t('Domains.List.domains')"
          :text="$t('Domains.List.all') + ': ' + domains.length"
          :search="search"
          @update:search="search=$event"
          @add="$router.push('/domains/new')"
        >
          <helper-table
            :headers="headers"
            :items="domains"
            item-value="name"
            :search="search"
            :prefix-cookies-name="prefixCookiesName"
          >
            <template #[`item.name`]="{ item }">
              <span
                class="clickable"
                @click="openDomain(item._id)"
              >
                {{ item.name }}
              </span>
            </template>
            <template #[`item.last_session`]="{ item }">
              {{ $getDate(item.last_session) }}
            </template>
          </helper-table>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";

export default {
    data() {
        return {
            prefixCookiesName: "Domains",
            search: '',
            pagination: {
                page: 1,
                itemsPerPage: 10,
            },

            windowWidth: 900,
            searchActive: false,
        };
    },

    computed: {
        domains() {
            return this.$store.getters.domains.map(d => {
                d.create_time = this.$getDate(d.create_time);
                return d;
            });
        },

        headers() {
            return [
                {title: this.$t('Domains.List.name'), sortable: true, align: "left", key: "name"},
                {title: this.$t('Domains.List.fullname'), sortable: false, align: "left", key: "full_name"},
                {title: this.$t('Domains.List.tin'), sortable: false, align: "left", key: "tin"},
                {title: this.$t('Domains.List.email'), sortable: false, align: "left", key: "email"},
                {title: this.$t('Domains.List.manager'), sortable: true, align: "left", key: "manager"},
                {title: this.$t('Domains.List.last_session'), sortable: true, key: "last_session"},
                {title: this.$t('Domains.List.devices'), sortable: false, children: [
                  {title: "online", sortable: false, key: "devices.online"},
                  {title: "warning", sortable: false, key: "devices.warning"},
                  {title: "critical", sortable: false, key: "devices.critical"}
                ]},
                // {title: "ID", sortable: false, key: "_id" }
            ]
        },
    },

    mounted() {
        this.$store.dispatch('getDomains');

        this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width;
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {

        onResize() {
            this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width
        },

        openDomain(domain_id) {
            this.$router.push({name: "domain", params: {domain_id: domain_id}});
        },

        searchActivate() {
            this.filterTags = new Set();
            this.searchActive = !this.searchActive;
            setTimeout(() => { this.$refs.search.focus() }, 100)
        },
    }
};
</script>
