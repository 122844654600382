<template>
  <v-form v-model="model.valid">
    <v-row>
      <v-col
        cols="12"
        md="9"
        lg="6"
        class="v-row"
      >
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="model.smtpHost"
            :rules="[required]"
            variant="solo-filled"
            persistent-hint
          >
            <template #label>
              {{ $t('Notifications.Transports.hostname') }}
              <span class="ml-1 mb-1">*</span>
            </template>
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="model.smtpPort"
            type="number"
            :rules="fieldRulesList.invalidPorts"
            variant="solo-filled"
            persistent-hint
          >
            <template #label>
              {{ $t('Notifications.Transports.port') }}
              <span class="ml-1 mb-1">*</span>
            </template>
          </v-text-field>
        </v-col>

        <v-col
          cols="6"
          md="2"
          class="px-0 col-chkbx"
        >
          <v-checkbox
            v-model="model.smtpSecure"
            :label="$t('Notifications.Transports.tls')"
            hide-details
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="9"
        lg="6"
        class="v-row"
      >
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="model.smtpUsername"
            :label="$t('Notifications.Transports.username')"
            variant="solo-filled"
            hide-details
          />
        </v-col>

        <v-col
          cols="12"
          md="5"
        >
          <custom-password-field
            v-model="model.smtpPassword"
            v-model:visibility="passVisibility"
            variant="solo-filled"
            :label="$t('Notifications.Transports.password')"
            :readonly="!pageLoaded"
            :required="false"
          />
        </v-col>

        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="model.smtpFrom"
            variant="solo-filled"
            autocomplete="nope"
            :rules="fieldRulesList.emailRules"
            hint="&quot;iRZ Electronics&quot; &lt;example@link.net&gt;"
            persistent-hint
          >
            <template #label>
              {{ $t('Notifications.Transports.from') }}
              <span class="ml-1 mb-1">*</span>
            </template>
          </v-text-field>
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="9"
        lg="6"
        class="v-row"
      >
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="model.customSubject"
            :label="$t('Notifications.Transports.subject')"
            variant="solo-filled"
            hide-details
          />
        </v-col>

        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="model.smtpTo"
            :label="$t('Notifications.Transports.to')"
            variant="solo-filled"
            autocomplete="email"
            hint="&lt;example2@link.net&gt;, &lt;example3@link.net&gt;"
            :rules="isSuperuser ? fieldRulesList.emailRules : fieldRulesList.emailRules.concat(required)"
            persistent-hint
          >
            <template #label>
              {{ $t('Notifications.Transports.to') }}
              <span
                v-if="!isSuperuser"
                class="ml-1 mb-1"
              >*</span>
            </template>
          </v-text-field>
        </v-col>
    
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="model.smtpCC"
            :label="$t('Notifications.Transports.cc')"
            variant="solo-filled"
            :rules="recipientRequired"
            hide-details="auto"
          />
        </v-col>
    
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="model.smtpBCC"
            :label="$t('Notifications.Transports.bcc')"
            variant="solo-filled" 
            :rules="recipientRequired"
            hide-details="auto"
          />
        </v-col>
      </v-col>
      <v-col
        v-if="isSuperuser"
        cols="12"
        md="9"
        lg="6"
        class="v-row d-flex align-end"
      >
        <v-card
          class="v-container"
          style="padding: 12px; max-width: 400px"
        >
          <!-- eslint-disable-next-line -->
          <div v-html="$t('Notifications.Transports.tooltip')"/>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";
import {fieldRulesList, required} from "@/plugins/fieldRules";

export default {
    props: {
        modelValue: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    emits: [ "update:modelValue" ],

    data() {
        return {
            pageLoaded: false,
            passVisibility: false,
        }
    },
    
    computed: {
        ...mapGetters(["isSuperuser"]),
        fieldRulesList() {
            return fieldRulesList
        },

        model: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        },

        recipientRequired() {
            return [
                v => !v || fieldRulesList.emailRules.map(r => r(v)).filter(e => e !== true).length === 0
            ];
        },
    },

    mounted() {
        if (typeof this.model.smtpSecure === "undefined") {
            this.model.smtpSecure = false;
        }

        if (!this.model.smtpTo) {
            this.model.smtpTo = this.$store.state.user.email;
        }

        setTimeout(() => {
            this.pageLoaded = true;
        }, 250);

    },
    
    beforeMount(){
        if (this.model.smtpPassword){
            this.model.smtpPassword = String(this.model.smtpPassword)
        }
    },

    methods: {required}
};
</script>

<style>
.col-chkbx {
  height: fit-content; 
  padding-top: 20px; 
  padding-bottom: 20px;
}
</style>