<template>
  <v-form v-model="model.valid">
    <v-row>
      <v-col
        cols="12"
        md="10"
        class="pb-0"
      >
        <custom-password-field
          v-model="model.telegramBotToken"
          v-model:visibility="passVisibility"
          variant="solo-filled"
          :label="$t('Notifications.Transports.token') + ' *'"
          :required="true"
        />
        <p>
          {{ $t("Notifications.Transports.token_description") }}
          <a
            href="https://t.me/BotFather"
            target="_blank"
          >
            https://t.me/BotFather
          </a>
        </p>
      </v-col>

      <v-col
        cols="12"
        md="10"
        class="py-0"
      >
        <v-text-field
          v-model="model.telegramChatID"
          :hint="$t('Notifications.Transports.support_chat')"
          variant="solo-filled"
          :rules="[required]" 
          append-inner-icon="irz-auto-fix"
          hide-details="auto"
          @click:append-inner="autoGetTelegramChatID"
        >
          <template #label>
            {{ $t('Notifications.Transports.chat') }}
            <span class="ml-1 mb-1">*</span>
          </template>
        </v-text-field>
        <p>
          {{ $t("Notifications.Transports.get_chat") + " " }}
          <a
            :href="telegramGetUpdatesURL('withToken')"
            target="_blank"
            style="word-break: break-word;"
          >{{ telegramGetUpdatesURL("masked") }}</a>
        </p>
      </v-col>

      <v-col
        cols="12"
        md="10"
        class="py-0"
      >
        <v-text-field
          v-model="model.telegramMessageThreadID" 
          :label="$t('Notifications.Transports.message_thread')"
          variant="solo-filled"
          :hint="$t('Notifications.Transports.message_thread_description')"
          hide-details="auto"
        />
      </v-col>

      <v-col
        cols="12"
        md="10"
      >
        <v-checkbox
          v-model="model.telegramSendSilently" 
          :label="$t('Notifications.Transports.telegram_send_silently')"
          persistent-hint 
          :hint="$t('Notifications.Transports.telegram_send_silently_description')"
          hide-details="auto"
        />

        <v-checkbox
          v-model="model.telegramProtectContent" 
          :label="$t('Notifications.Transports.telegram_protect_content')"
          persistent-hint 
          :hint="$t('Notifications.Transports.telegram_protect_content_description')"
          hide-details="auto"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import {required} from "@/plugins/fieldRules";

export default {
    props: {
        modelValue: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    emits: [ "update:modelValue" ],

    data() {
        return {
            passVisibility: false,
        }
    },

    computed: {
        model: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    },

    methods: {
        required,
        /**
         * Get the URL for telegram updates
         * @param {string} [mode=masked] Should the token be masked?
         * @returns {string} formatted URL
         */
        telegramGetUpdatesURL(mode = "masked") {
            let token = `<${this.$t("YOUR BOT TOKEN HERE")}>`;

            if (this.model.telegramBotToken) {
                if (mode === "withToken") {
                    token = this.model.telegramBotToken;
                } else if (mode === "masked") {
                    token = "*".repeat(this.model.telegramBotToken.length);
                }
            }

            return `https://api.telegram.org/bot${token}/getUpdates`;
        },

        /** Get the telegram chat ID */
        async autoGetTelegramChatID() {
            try {
                let res = await axios.get(this.telegramGetUpdatesURL("withToken"));

                if (res.data.result.length >= 1) {
                    let update = res.data.result[res.data.result.length - 1];

                    if (update.channel_post) {
                        this.model.telegramChatID = update.channel_post.chat.id;
                    } else if (update.message) {
                        this.model.telegramChatID = update.message.chat.id;
                    } else {
                        this.$store.dispatch('addInfoMessage', this.$t("Notifications.Transports.chat_not_found"));
                    }

                } else {
                    this.$store.dispatch('addInfoMessage', this.$t("Notifications.Transports.chat_not_found"));
                }

            } catch (error) {
                this.$store.dispatch('addInfoMessage', error.message);
            }

        },
    },
};
</script>
