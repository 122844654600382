// https://vuex.vuejs.org/en/state.html

import  { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  drawer: null,
  // image: '/wp.png',
  // sidebarBackgroundColor: 'rgba(255, 255, 255, 0.74)',

  isLoading: false,
  isDarkTheme: JSON.parse(cookies.get("isDarkTheme")) || false,
  color: JSON.parse(cookies.get("color")) || "dusk",
  status: "",
  user: JSON.parse(localStorage.getItem("user") || "{}"),
  dashboard: cookies.get("dash") || {},

  info_msg: [],
  info_msg_cached: [],
  notifications: [],
  notificationRules: [],

  extra: {},

  logs: [],
  backups: [],
  commons: [],
  command: {},
  commands: [],
  reports: [],
  templates: [],
  users: [],
  domains: [],
  devices: new Map(),
  targetDevices: [],
  events: [],
  tags: new Set(),
  tagsFrequency: [],
  settings: {},
  crontab: [],
  policies: [],

  ws: null,
};
