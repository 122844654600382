<template>
  <div
    ref="inputbox"
    class="input-box"
  >
    <v-text-field
      v-model="newtag"
      :label="$t('Devices.Edit.tags')"
      :style="newfocused ? 'border-bottom: 1px solid rgb(210, 210, 210);' : ''"
      :rules="tagRules"
      class="input-visible"
      variant="solo-filled"
      :read-only="isStaff"
      @keyup.enter="addTag($event.target.value)"
      @keydown.tab.prevent="newtag = hint"
      @keypress="inputSymb"
      @update:focused="newfocused = true"
    >
      <span
        ref="hint"
        class="p-0 input-hint"
        :style="newfocused ? '' : 'display: none'"
      >
        {{ hint }}
      </span>
    </v-text-field>

    <slot
      name="menu"
    />
  </div>
</template>

<script>
import {fieldRulesList} from "@/plugins/fieldRules";

export default{
    props: {
        tags: {
            type: Set,
            default: new Set(),
            required: true
        },
        focused: {
            type: Boolean,
            default: false
        },
        addTag: {
            type: Function,
            required: true
        },
        isStaff: {
            type: Boolean,
            default: false,
        },
        tag: {
            type: String,
            default: ""
        },
    },
    emits: ["update:tag", "update:focused"],
    data() {
        return {
            hint: '',
            newtag: this.tag,
            newfocused: this.focused,

            tagRules: fieldRulesList.tagRules,
        }
    },
    watch: {
      tag() {
        this.newtag = this.tag;
      },
      newfocused() {
        this.$emit("update:focused", this.newfocused);
      },
      newtag(val) {
        this.$emit("update:tag", this.newtag);
        this.hint = val;
        if (!val) {
          return;
        }
        for (let i of this.tags) {
          if (i.startsWith(val)) {
            this.hint = i
            break
          }
        }
      },
    },
    methods: {
        inputSymb(ev) {
            if (!ev.key.match(/[a-zA-Z0-9а-яА-Я_-]/)) ev.preventDefault();
        },
    }
}
</script>

<style>
  .input-hint {
    position:absolute;
    z-index: 2;
    background: transparent;
    opacity: 0.3;
    width: 100%;
  }

  .input-visible {
    position: relative;
  }

  .input-box {
    height: auto;
    position: relative;
    display: flex;
  }

  .input-box input {
    outline: none;
  }
</style>