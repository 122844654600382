<template>
  <div class="my-3 py-3 px-3 elevation-1 modal-content">
    <v-checkbox-btn
      v-model="isOpen"
      :label="heading"
      true-icon="irz-chevron-up"
      false-icon="irz-chevron-down"
    />
    <transition name="slide-fade-up">
      <div
        v-if="isOpen"
        class="mt-3"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
    props: {
        /** Heading of the section */
        heading: {
            type: String,
            default: "",
        },
        /** Should the section be open by default? */
        defaultOpen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: this.defaultOpen,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "../../styles/kuma/vars";

.v-checkbox-btn {
    padding-top: 8px;
}

</style>
