/**
 * Vue i18n
 *
 * @library
 *
 * http://kazupon.github.io/vue-i18n/en/
 */

// Lib imports
import { createI18n } from 'vue-i18n'
import messages from "../lang";

const i18n = createI18n({
  locale: 'ru' || 'en',
  fallbackLocale: 'ru' || 'en',
  legacy: false,
  globalInjection: true,
  messages
})

i18n.global.td = (key, def) => i18n.global.te(key) ? i18n.global.t(key) : (def || key);

const installI18n = i18n.install;
i18n.install = function(vue) {
  installI18n(vue);
  vue.config.globalProperties.$td = i18n.global.td;
}

export { i18n }
