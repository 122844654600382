<template>
  <v-container :fluid="true">
    <v-row
      justify="center"
      wrap
    >
      <v-col cols="12">
        <material-card
          :variant="isSuperuser || isAdmin ? 'header--search--action-add' : 'header--search'"
          :title="$t('Users.List.' + role + 's')"
          :text="$t('Users.List.all') + ': ' + users.length"
          :search="search"
          @update:search="search=$event"
          @add="openUser('new')"
        >
          <tables-user-table
            :headers="headers"
            :search="search"
            :prefix-cookies-name="prefixCookiesName"
          >
            <template #[`item.username`]="{ item }">
              <span
                class="clickable"
                @click="openUser(item._id)"
              >
                {{ item.username }}
              </span>
            </template>

            <template #[`item.role`]="{ item }">
              {{ getUserRole(item.role) }}
            </template>

            <template #[`item.policy`]="{ item }">
              <span
                class="clickable"
                @click="openPolicy(item.policy._id)"
              >
                {{ item.policy?.name }}
              </span>
            </template>

            <template #[`item.last_session`]="{ item }">
              {{ $getDate(item.last_session) }}
            </template>

            <template #[`item.blocked`]="{ item }">
              {{ item.blocked ? $t('Users.List.yes') : $t('Users.List.no') }}
            </template>
          </tables-user-table>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import {fetchData} from "@/plugins/fetchData.js";
import {capitalize} from "@vue/shared";
import {mapState, mapGetters} from "vuex";
import {win} from "codemirror/src/util/dom";

export default {
    data() {
        return {
            prefixCookiesName: "Users",
            search: '',
            pagination: {
                page: 1,
                itemsPerPage: 10,
            },

            windowWidth: 900,
        };
    },

    computed: {
        ...mapState(["users"]),
        ...mapGetters(["isSuperuser", "isAdmin", "hasAccounting", "isHelper"]),

        role() {
            return this.isSuperuser ? 'superuser' : 'user';
        },

        headers() {
            return [
                {title: this.$t('Users.List.login'), sortable: true, key: "username"},
                {title: this.$t('Users.List.fullname'), sortable: true, key: "full_name"},
                {title: this.$t('Users.List.role'), align: "left", key: "role"},
                {title: this.$t('Users.List.mail'), sortable: true, key: "email"},
                {title: this.$t('Users.List.policy'), sortable: true, key: "policy", condition: (this.isSuperuser || this.isAdmin) && this.hasAccounting},
                {title: this.$t('Users.List.last_login'), sortable: true, key: "last_session"},
                {title: this.$t('Users.List.blocked'), sortable: true, key: "blocked"}
            ].filter(h => h.condition !== false);
        }
    },

    beforeMount() {
        this.$store.dispatch('getUsers');

        this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width;
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {
        win,
        capitalize,

        onResize() {
            this.windowHeight = window.innerHeight
            this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width
        },

        openUser(user_id) {
            this.$router.push({name: this.role, params: {
                user_id: user_id
            }});
        },

        openPolicy(policy_id) {
            this.$router.push({name: 'policy', params: {
                policy_id: policy_id
            }});
        },
        
        getUserRole(r) {
            switch (r) {
                case 1:
                    return this.$t('Users.List.root');
                case 2:
                    return this.$t('Users.List.admin');
                case 3:
                    return this.$t('Users.List.offer');
                case 4:
                    return this.$t('Users.List.operator');
                default:
                    return "Anonymous";
            }
        },
    }
};
</script>
