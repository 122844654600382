// https://vuex.vuejs.org/en/mutations.html
import { set, toggle } from '@/utils/vuex'
import  { useCookies } from "vue3-cookies"

const { cookies } = useCookies();

function staticChangeRoleColor(state) {
  if (state.isDarkTheme) {
    state.color = "dusk";
  } else {
    switch (state.user.role) {
      case 1:
        state.color = "root";
        break;
      case 2:
        state.color = "admin";
        break;
      case 3:
        state.color = "assistant";
        break;
      case 4:
        state.color = "operator";
        break;
      default:
        state.color = "dusk";
    }
  }
  cookies.setJSON('color', state.color);
}

function setPriorityInfo(msg, priority) {
  msg = msg && msg.url ? msg : {message: msg}
  msg.priority = priority;
  return msg
}

export default {
  setDrawer: set('drawer'),
  setImage: set('image'),
  setColor: set('color'),
  toggleDrawer: toggle('drawer'),
  
  toggleNight: toggle('night'),

  updateNotificationsList(state, payload) {
    state.notifications = payload;
  },

  updateNotificationRules(state, payload) {
    state.notificationRules = payload;
  },

  pushNewNotification(state, payload) {
    state.notifications.push(payload)
  },

  showInfo(state, msg) {
    state.info_msg.push(setPriorityInfo(msg, 0));
  },

  showWarning(state, msg) {
    if (state.info_msg?.at(0)?.priority > 1) {
      state.info_msg.push(setPriorityInfo(msg, 1));
    } else {
      state.info_msg = [setPriorityInfo(msg, 2)];
    }
  },

  showCritical(state, msg) {
    state.info_msg = [setPriorityInfo(msg, 2)];
  },

  switchDarkTheme(state, payload) {
    state.isDarkTheme = payload === true || payload === "true";
    cookies.setJSON('isDarkTheme', payload);
    staticChangeRoleColor(state)
  },

  changeRoleColor(state) {
    return staticChangeRoleColor(state);
  },

  // auth_request(state){
  //   // state.status = 'loading'
  // },
  
  auth_success(state, user){
    // state.status = 'success'
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  
  // auth_error(state){
  //   // state.status = 'error'
  // },
  
  logout(state){
    // state.status = ''
    state.user = {}

    state.info_msg = []
    state.notifications = []

    state.scripts = []
    state.packages = []
    state.firmwares = []
    state.logs = []
    state.backups = []
    state.commons = []
    state.commands = []
    state.reports = []
    state.templates = []
    state.users = []
    state.dashboard = {}
    state.settings = {}
    state.domains = []
    state.devices = new Map()
    state.tags = []
    state.dashboard = []

    localStorage.removeItem('user')

  },

  setLogs(state, payload) {
    state.logs = payload
  },
  setBackups(state, payload) {
    state.backups = payload
  },
  setCommons(state, payload) {
    state.commons = payload
  },
  setCommand(state, payload) {
    state.command = payload
  },
  setCommands(state, payload) {
    state.commands = payload
  },
  setTemplates(state, payload) {
    state.templates = payload
  },
  setUsers(state, payload) {
    state.users = payload
  },
  setDomains(state, payload) {
    state.domains = payload
  },
  setDevices(state, payload) {
    state.devices = payload
  },
  setTargetDevices(state, payload) {
    state.targetDevices = payload
  },
  setEvents(state, payload) {
    state.events = payload
  },
  setPolicies(state, payload) {
    state.policies = payload
  },
  setTags(state, payload) {
    state.tags = new Set(payload)
  },
  setTagsFrequency(state, payload) {
    state.tagsFrequency = payload
  },
  setDashboard(state, payload) {
    state.dashboard = payload
  },
  setSettings(state, payload) {
    state.settings = payload.settings
  },
  setCrontab(state, payload) {
    state.crontab = payload
  },

  AddReport(state, payload) {
    state.reports.push(payload)
  },
  addDevice(state, payload) {
    state.devices.set(payload.ident, payload);
  },
  deleteDevice(state, payload) {
    state.devices.delete(payload.ident);
  },
}
