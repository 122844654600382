<template>
  <v-container :fluid="true">
    <v-row
      justify="center"
      wrap
    >
      <v-col cols="12">
        <material-card
          :variant="$store.getters.isSuperuser ? 'header--search--action-add' : 'header--search'"
          :title="$t('Security.List.policies')"
          :text="$t('Security.List.all') + ': ' + policies.length"
          :search="search"
          @update:search="search=$event"
          @add="openPolicy('new')"
        >
          <helper-table
            :headers="headers"
            :items="policies"
            :search="search"
            :prefix-cookies-name="prefixCookiesName"
          >
            <template #item="{ item }">
              <tr>
                <td>
                  <span
                    class="clickable"
                    @click="openPolicy(item._id)"
                  >
                    {{ item.name }}
                  </span>
                </td>
                <td>{{ levelTitle(item.policy_level) }}</td>
              </tr>
            </template>
          </helper-table>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import {fetchData} from "@/plugins/fetchData.js";

export default {
    data() {
        return {
            prefixCookiesName: "Security",
            search: '',
            pagination: {
                page: 1,
                itemsPerPage: 10,
            },

            windowWidth: 900,
        };
    },

    computed: {
        policies() {
            return this.$store.getters.policies.filter(p => p._id && p._id !== 'default');
        },

        headers() {
            return [
                {title: this.$t('Security.List.name'), sortable: true, key: "name"},
                {title: this.$t('Security.List.policy_level'), sortable: true, key: "policy_level"},
            ]
        },

        levels() {
            return ['low', 'medium', 'hard'];
        },
    },

    beforeMount() {
        this.$store.dispatch('getAccounting');

        this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width;
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {
        onResize() {
            this.windowHeight = window.innerHeight
            this.windowWidth = document.body.getElementsByTagName('main')[0].getBoundingClientRect().width
        },

        openPolicy(policy_id) {
            this.$router.push({name: 'policy', params: {
                policy_id: policy_id
            }});
        },

        levelTitle(l) {
            return this.$t('Security.List.' + this.levels[l] + '_level');
        },
    }
};
</script>
