<template>
  <label class="file-select">
    <div
      :class="[buttonClass, 'select-button--' + variant,
               variant === 'elevated' ? 'v-card v-card--variant-elevated' : '', 
               variant === 'elevated' && !$store.state.isDarkTheme ? 'bg-' + $store.state.color : '',
               $store.state.isDarkTheme ? 'v-theme--dark' : 'v-theme--light',
               'tooltip_container', divClass]"
      @dragenter="dragenter"
      @dragleave="dragleave"
      @dragend="dragend"
    >
      <div class="v-btn__overlay" />
      <v-icon
        v-if="prependIcon"
        class="mr-3"
        size="large"
      >{{ prependIcon }}</v-icon>
      <span v-if="modelValue">{{ modelValue.name }}</span>
      <span v-else-if="divClass">{{ $t('Custom.FileSelect.drop') }}</span>
      <span v-else>{{ $t('Custom.FileSelect.' + text) }}</span>
      <v-icon
        v-if="info"
        class="ml-3"
        size="large"
        @mouseenter="enter"
        @mouseleave="leave"
      >
        mdi-information-outline
      </v-icon>
      <div
        v-if="$slots.info"
        class="tooltip_text"
        style="display: none; overflow: hidden"
      >
        <slot name="info" />
      </div>
    </div>
    <input
      ref="file_in"
      type="file"
      :multiple="multiple"
      @change="handleFileChange"
    >
  </label>
</template>

<script>
export default {
    props: {
        modelValue: File,
        info: {
            type: Boolean,
        },
        text: {
            type: String,
            default: 'file-select'
        },
        variant: {
            type: String,
            default: 'outline'
        },
        buttonClass: {
            type: String,
            default: 'v-btn',
        },
        prependIcon: {
            type: String,
            default: ""
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            draglevel: 0,
            dragenterlevel: 0,
            events: ['dragstart', 'dragend', 'dragenter', 'dragover', 'dragleave', 'drop'],
            divClass: '',
            overlay: false,
        }
    },

    mounted() {
        this.events.forEach((eventName) => {
            document.body.addEventListener(eventName, e => this.preventDefaults(e, eventName))
        })
    },

    beforeUnmount() {
        this.events.forEach((eventName) => {
            document.body.removeEventListener(eventName, e => this.preventDefaults(e, eventName))
        })
    },

    methods: {

        enter() {
            let tooltip = document.querySelector('.tooltip_text');
            if (tooltip) {
                tooltip.style.display = 'inherit';
            }
        },

        leave() {
            let tooltip = document.querySelector('.tooltip_text');
            if (tooltip) {
                tooltip.style.display = 'none';
            }
        },

        dragenter() {
            if (this.dragenterlevel++ === 0)
                this.divClass = 'enter_div';
        },

        dragleave() {
            if (--this.dragenterlevel === 0)
                this.divClass = 'start_div';
        },

        dragend() {
            this.dragenterlevel = 0;
            this.divClass = '';
        },

        preventDefaults(e, eventName) {
            switch (eventName) {
                case 'dragenter':
                    if (this.draglevel++ === 0)
                        this.divClass = 'start_div'
                    break
                case 'dragleave':
                    if (--this.draglevel === 0)
                        this.divClass = ''
                    break
                case 'dragend':
                    this.dragend(e);
                    break
                case 'drop':
                    this.onDrop(e)
                    break
            }
            e.preventDefault()
        },

        handleFileChange(e) {
            this.$emit('update:modelValue', this.multiple ? Array.from(e.target.files) : e.target.files[0]);
            this.$refs.file_in.value = '';
        },

        onDrop(e) {
            this.dragend(e);
            this.$emit('update:modelValue', this.multiple ? Array.from(e.dataTransfer.files) : e.dataTransfer.files[0]);
        }
    }
}
</script>

<style lang="scss" scoped>
.file-select {
    height: 100%;
}

.file-select > .select-button--outline,
.file-select > .select-button--text,
.file-select > .select-button--elevated {
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    width: 100%;
}

.file-select > .select-button--text {
    font-size: 12px;
    font-weight: 400!important;
    text-transform: uppercase;
    padding: 0.7rem;
}

.file-select > .select-button--elevated {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.v-theme--light .file-select > .select-button--outline {
    padding: 1rem;
    color: #0052cc;
    border: 1px solid #0052cc;
}

.v-theme--dark .file-select > .select-button--outline {
    padding: 1rem;
    color: white;
    border: 1px solid white;
}

.file-select > input[type="file"] {
    display: none;
}

.v-theme--light .select-button--outline > i {
    color: #2e307f;
}

.enter_div {
    background-color: #999 !important;
}

.v-theme--light .start_div {
    background-color: #e0e0e0 !important;
}

.v-theme--dark .start_div {
    background-color: #444 !important;
}

.select-button--outline:hover > .v-btn__overlay,
.select-button--text:hover > .v-btn__overlay {
    opacity: calc((.01 + var(--v-focus-opacity))*var(--v-theme-overlay-multiplier));
}

</style>
