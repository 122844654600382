<template>
  <div
    v-bind="$attrs"
    :style="styles"
    class="mx-3 pt-3"
  >
    <v-row class="justify-content-between mx-0 my-3 mb-1">
      <slot
        v-if="$slots.header"
        name="header"
      />

      <h1
        v-else-if="title"
        class="title font-weight-medium ml-5 my-1"
      >
        {{ title }}
      </h1>

      <div
        v-if="variant === 'action-box'"
        class="v-card w-auto"
      >
        <div class="action-box">
          <slot name="actions" />
        </div>
      </div>
    </v-row>

    <helper-offset
      v-if="hasOffset"
      :inline="true"
      :full-width="true"
      :offset="24"
    >
      <slot
        v-if="$slots.offset"
        name="offset"
      />

      <div
        v-if="variant === 'action' || variant === 'action-tabs'"
        class="pt-2"
      >
        <slot name="actions" />
      </div>
    </helper-offset>

    <v-container
      :fluid="true"
      fill-height
      grid-list-xl
      class="v-card p-0"
    >
      <v-row>
        <v-col>
          <slot />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {

    props: {
        variant: {
            type: String,
            default: undefined
        },
        title: {
            type: String,
            default: undefined
        },
        backable: {
            type: Boolean,
            default: false
        },
        offset: {
            type: [Number, String],
            default: 0
        }
    },

    data() {
        return {

        }
    },

    computed: {
        hasOffset() {
            return this.title || this.$slots.offset || this.$slots.actions
        },
        styles() {
            if (!this.hasOffset) return null

            return {
                marginBottom: `${this.offset}px`,
                marginTop: `${this.offset}px`
            }
        },
    },

    methods: {

    }
};
</script>

<style lang="scss">
.action-box {
    display: flex; 
    align-items: center;
    padding: 0;
    height: 40px;
}
</style>
