import axios from 'axios';
import {store} from "../store";
import {i18n} from "../i18n";

export async function fetchData(method = 'get', url = '/', data = {}, headers = {}, showError = () => { return true}) {
    if (method.toLowerCase() !== 'get' && !(data.token)) {
        // if (method.toLowerCase() == 'delete') {
        //   data = { data: data };
        // }
        data.token = store.state.user.token;
    } else {
        headers.Token = store.state.user.token;
    }

    let loader = setTimeout(function () {
        store.state.isLoading = true;
    }, 1000);

    return axios({method, url, data, headers, responseType: headers.responseType})
        .catch((err) => {
            switch (err.response.status) {
                case 401:
                case 403:
                case 502:
                    store.dispatch('logout');
                    document.location = '/login';
                    if (showError(err.response.status))
                        store.dispatch('addCriticalMessage', i18n.global.t('Custom.Error.' + err.response.data));
                    break

                // TODO: исправить
                case 500:
                case 501:
                    if (showError(err.response.status))
                        store.dispatch('addCriticalMessage', i18n.global.t('Custom.Push.server_error') + ': ' +
                            i18n.global.t('Custom.Error.' + err.response.data));
                    break;

                case 400:
                    if(showError(err.response.status))
                        store.dispatch('addCriticalMessage', i18n.global.t('Custom.Error.' + err.response.data));
                    break;

                default:
                    if (showError(err.response.status))
                        store.dispatch('addCriticalMessage', i18n.global.t('Custom.Push.server_error') +
                            ' by ' + url + ': ' + i18n.global.t('Custom.Error.' + err.response.data));
                    // if(err.response.data === 'TOKEN_NOT_GIVEN')
                    //   document.location = '/login';
                    break;
            }
            throw(err)
        })
        .then((resp) => resp)
        .finally(() => {
            clearTimeout(loader);
            store.state.isLoading = false;
        })
}
