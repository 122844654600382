<template>
  <v-form v-model="model.valid">
    <v-row v-if="model.type === 'timer'">
      <v-col cols="12">
        <v-label style="padding: 10px;">
          {{ $t('Notifications.Monitors.timer') }}
        </v-label>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="model.check_delay" 
          variant="solo-filled"
          :label="$t('Notifications.Monitors.check_delay')"
          type="number" 
          :rules="[matchRule('^([1-9][0-9]*)$')]"
        />
      </v-col>
    </v-row>

    <v-row v-if="model.type === 'reactive'">
      <v-label style="padding: 10px;">
        {{ $t('Notifications.Monitors.reactive') }}
      </v-label>
    </v-row>

    <v-row v-if="model.props && model.props.variables">
      <v-col
        v-for="(v, k) in model.props.variables"
        :key="k"
        cols="12"
      >
        <v-text-field
          v-model="model[k]"
          variant="solo-filled"
          :label="$t('Notifications.Monitors.' + k)"
          :hint="v"
          :rules="[matchRule('^(' + v + ')$')]"
          persistent-hint
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {fieldRulesList, matchRule} from "@/plugins/fieldRules";

export default {
    props: {
        modelValue: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    emits: [ "update:modelValue" ],

    computed: {
        fieldRulesList() {
            return fieldRulesList
        },
        model: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        },
    },

    methods: {
        matchRule,
    }
}
</script>

<style scoped>

</style>
