<template>
  <v-main>
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <div>
          <div
            v-if="passwordExpiredSoon"
            :class="['password-expire-soon', 'small']"
          >
            {{ $t('Core.View.passwordExpiredSoon') + passwordExpiredAfter }}.
          </div>

          <router-view />
        </div>
      </v-fade-transition>
    </div>
    <core-footer />
  </v-main>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    metaInfo() {
        return {
            title: 'iRZ Link'
        }
    },

    data() {
        return {
            fromTime: new Date().getTime()
        }
    },

    computed: {
        ...mapGetters(["passwordExpiredSoon"]),

        passwordExpiredAfter() {
            let exp = this.$store.state.user.password_expire_date;
            let t = this.$timeSince(this.fromTime, exp);
            if (t && t !== true)
                return ' ' + this.$t('Plugins.Time.throw') + ' ' + t;
            return '';
        },
    },

    created() {
        this.timeUpdate = setInterval(() => {
            this.fromTime = new Date().getTime()
        }, 60 * 1000)
    },

    beforeUnmount() {
        if (this.timeUpdate)
            clearInterval(this.timeUpdate)
    },
}
</script>

<style scoped>

.go-to-profile {
    cursor: pointer;
    /*font-weight: 700;*/
    text-decoration-style: dotted;
    color: white;
}

.go-to-profile:hover {
    color: #eaeaea;
}

.password-expire-soon-blocker {
    position: absolute;
    top: 0;
    left: 0;
    width: -webkit-fill-available;
    height: 100%;
    z-index: 1005;
    background-color: #0000007a;
}

.password-expire-soon {
    z-index: 1005;
    position: fixed;
    text-align: center;
    vertical-align: middle;
    padding: 1em 0;
    width: -webkit-fill-available;
    font-size: 1em;
    color: white;
    background-color: #ef5350;
    bottom: 0;
}

#core-view {
    padding-top: 40px;
    padding-bottom: 56px;
}
</style>
